import { OrderRequestData, SubmitData, SumbitDataMite } from '@src/types';

import type {
  OrderDataTypes,
  OrderInitDataTypes,
  OrderSubmitDataTypes,
} from './order-store.types';

export interface OrderStoreState {
  order?: OrderDataTypes;
  orderSubmitData?: OrderSubmitDataTypes;
  orderInitData?: OrderInitDataTypes;
  orderPageTitle?: string;
  orderRequestData?: OrderRequestData | SubmitData | SumbitDataMite;
  cachedOrderRequestData?: OrderRequestData | SubmitData | SumbitDataMite;
  themeValue?: string;
}

export const initOrderStoreState: OrderStoreState = {
  order: undefined,
  orderSubmitData: undefined,
  orderInitData: undefined,
  orderPageTitle: undefined,
  orderRequestData: undefined,
  cachedOrderRequestData: undefined,
  themeValue: undefined,
};
