import { useContext, useEffect } from 'react';

import { Store } from '@src/store';
import { setDraftLS } from '@src/utils';

export const useSportDraft = () => {
  const {
    state: {
      stateFormNSSport: {
        insuranceProduct,
        insuranceProductDate,
        selectedStartDate,
        selectedEndDate,
        numberInsurePersons,
        promoCode,
        sportKinds,
        choosedSportKinds,
        risks,
        sublimit,
        insuranceSum,
        insurancePrice,
        insurePersons,
        selectedDuration,
      },
      stateFormNSSport,
      stateUser: { selectedProduct },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    const data = {
      dataObject: stateFormNSSport,
      selectedProduct,
      currentStep: currentStep || 0,
    };

    setDraftLS(data);
  }, [
    insuranceProduct,
    insuranceProductDate,
    selectedStartDate,
    selectedEndDate,
    numberInsurePersons,
    promoCode,
    sportKinds,
    choosedSportKinds,
    risks,
    sublimit,
    insuranceSum,
    insurancePrice,
    insurePersons,
    selectedDuration,
  ]);

  return;
};
