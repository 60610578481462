/* eslint-disable indent */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MoneyRangeInput } from '@pulse-web-ui/range-input';
import { SelectorCard } from '@pulse-web-ui/selector-card';

import { SectionContainer } from '@src/common-components/container';
import {
  PageSubTitle,
  PageTitle,
  SectionTitle,
} from '@src/common-components/headers';
import {
  AdaptiveListWrapper,
  Container,
  RiskWrapper,
  Skeleton,
} from '@src/components';
import { GlobalErrorInfo, InsurancePriceCard, PromoCode } from '@src/features';
import {
  useHandlePricesError,
  useNextStep,
  useRequest,
  useRequests,
} from '@src/hooks';
import { SportNSActionTypes, Store, WizardActionTypes } from '@src/store';
import { getDaysDuration, getFormattedDate, toNum } from '@src/utils';

import { useSportDraft } from './hooks';

export const FormInsuranceSportNSCoverage = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormNSSport: {
        insuranceProduct,
        risks,
        sublimit,
        insuranceSum,
        numberInsurePersons,
        choosedSportKinds,
        promoCode,
        promoCodeApplyed,
        insurancePrice,
        selectedStartDate,
        selectedEndDate,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const [rangeInputError, setRangeInputError] = useState<string | undefined>();
  const [autoInitPromo, setAutoInitPromo] = useState(false);
  const [formPromoCode, setFormPromoCode] = useState<string | undefined>(
    promoCode
  );
  const [isReadyToRefetchPrices, setIsReadyToRefetchPrices] = useState(false);

  const insuranceSumChange = (val: number | undefined) => {
    dispatch({
      type: SportNSActionTypes.SetInsuranceSum,
      payload: val,
    });
  };

  const {
    isLoading,
    isError,
    res: [risksResponse, sublimitResponse],
    refetchAll,
  } = useRequests([
    {
      key: 'getSportRisks',
      method: 'post',
      url: '/v1/references/get-risks',
      request: { productCode: insuranceProduct?.code },
      deps: [],
    },
    {
      key: 'getSportSublimit',
      method: 'post',
      url: '/v3/references/sport/get-sublimits',
      request: { productCode: insuranceProduct?.code },
      deps: [],
    },
  ]);

  const {
    isLoading: isPricesLoading,
    error: pricesError,
    res: pricesResponse,
    refetch: refetchPrices,
  } = useRequest(
    'getSportPrices',
    'post',
    '/v3/subscription/sport/get-prices',
    {
      effectiveSince: selectedStartDate && getFormattedDate(selectedStartDate),
      contractDuration: `P${
        selectedStartDate && selectedEndDate
          ? getDaysDuration(selectedStartDate, selectedEndDate)
          : ''
      }D`,
      productCode: insuranceProduct?.code,
      insuranceSum: insuranceSum?.toString() || '',
      numberOfInsured: numberInsurePersons?.toString() || '',
      sports: choosedSportKinds?.map(({ value }) => value),
      risks: risks?.map(({ code }) => code),
      promoCode:
        autoInitPromo && formPromoCode && formPromoCode?.length > 0
          ? formPromoCode
          : undefined,
    },
    [
      formPromoCode,
      autoInitPromo,
      insuranceSum,
      authTokens?.authorization?.accessToken,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (promoCodeApplyed && insurancePrice?.isSuccessfulPromo) {
      setFormPromoCode(promoCodeApplyed);
      setAutoInitPromo(true);
    }
  }, []);

  const submitPage = (val?: string) => {
    dispatch({
      type: SportNSActionTypes.SetPromoCode,
      payload: val,
    });

    dispatch({
      type: SportNSActionTypes.SetPromoCodeApplyed,
      payload: undefined,
    });

    setFormPromoCode(val);
    setAutoInitPromo(true);
  };

  const updatePromoCode = (val?: string) => {
    dispatch({
      type: SportNSActionTypes.SetPromoCode,
      payload: val,
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (insuranceSum != null && isReadyToRefetchPrices) {
        refetchPrices();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [insuranceSum, isReadyToRefetchPrices]);

  useEffect(() => {
    if (autoInitPromo && formPromoCode) {
      refetchPrices();
    }
  }, [autoInitPromo, formPromoCode]);

  useEffect(() => {
    if (!isLoading && risksResponse?.data?.risks) {
      dispatch({
        type: SportNSActionTypes.SetRisks,
        payload: risksResponse?.data?.risks,
      });
    }
  }, [isLoading, risksResponse?.data]);

  useEffect(() => {
    if (!isLoading && sublimitResponse?.data) {
      dispatch({
        type: SportNSActionTypes.SetSublimit,
        payload: sublimitResponse?.data,
      });

      const maxLimit = toNum(sublimitResponse?.data?.totalMaxLimit) as number;

      if (!!insuranceSum && insuranceSum > maxLimit) {
        insuranceSumChange(maxLimit);
      }

      setIsReadyToRefetchPrices(true);
    }
  }, [isLoading, sublimitResponse?.data]);

  useEffect(() => {
    if (isError) {
      setIsReadyToRefetchPrices(true);
    }
  }, [isError]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: isError || isLoading || !!pricesError || isPricesLoading,
    });
  }, [isError, isLoading, pricesError, isPricesLoading]);

  useEffect(() => {
    if (!insuranceSum) {
      insuranceSumChange(toNum(sublimit?.totalDefaultInsuranceSum));
    }
  }, [sublimit?.totalDefaultInsuranceSum, insuranceSum]);

  useEffect(() => {
    if (insuranceSum && isReadyToRefetchPrices) {
      dispatch({
        type: SportNSActionTypes.SetPromoCode,
        payload: formPromoCode,
      });
      refetchPrices();
    }
  }, [formPromoCode, isReadyToRefetchPrices]);

  useEffect(() => {
    if (!(!isPricesLoading && !!pricesResponse)) {
      return;
    }
    setRangeInputError(undefined);

    dispatch({
      type: SportNSActionTypes.SetInsurancePrice,
      payload: pricesResponse,
    });

    if (
      pricesResponse?.isSuccessfulPromo &&
      promoCode !== '' &&
      !promoCodeApplyed
    ) {
      dispatch({
        type: SportNSActionTypes.SetPromoCodeApplyed,
        payload: promoCode,
      });
    }
  }, [isPricesLoading, pricesResponse]);

  const validatePage = useCallback(() => {
    return !rangeInputError;
  }, []);

  useNextStep(validatePage);

  useSportDraft();

  const pricesGlobalError = useHandlePricesError(
    pricesError,
    refetchPrices,
    () => {
      if (!rangeInputError) {
        setRangeInputError(t('SPORT_FORM:errors.tooExpensivePolicy') || '');
      }
    }
  );

  if (isLoading) return <Skeleton />;

  if (isError) {
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetchAll} />;
  }

  if (pricesGlobalError) {
    return pricesGlobalError;
  }

  return (
    <Container>
      <PageTitle>{t('SPORT_FORM:titles.coverage')}</PageTitle>
      <PageSubTitle>{t('SPORT_FORM:subTitles.coverage')}</PageSubTitle>

      <SectionContainer>
        <SectionTitle>{t('SPORT_FORM:sectionTitles.risks')}</SectionTitle>
        <AdaptiveListWrapper>
          {risks?.map((risk) => (
            <RiskWrapper>
              <SelectorCard
                key={risk.code}
                id={risk.code}
                name={risk.code}
                label={risk.name}
                description={risk.description}
                readOnly={!risk.switchability}
              />
            </RiskWrapper>
          ))}
        </AdaptiveListWrapper>
      </SectionContainer>
      <SectionContainer>
        <MoneyRangeInput
          isPriceFormatted
          readOnly
          label={t('SPORT_FORM:labels.coveragePrice') || ''}
          value={insuranceSum || 0}
          min={toNum(sublimit?.totalMinLimit) || 0}
          max={toNum(sublimit?.totalMaxLimit) || 0}
          step={toNum(sublimit?.totalStep) || 0}
          onChange={insuranceSumChange}
          error={!!rangeInputError}
          helperErrorText={rangeInputError}
        />
      </SectionContainer>
      <SectionContainer>
        <PromoCode
          promoCode={
            promoCodeApplyed && insurancePrice?.isSuccessfulPromo
              ? promoCodeApplyed
              : formPromoCode
          }
          isSuccessfulPromo={pricesResponse?.isSuccessfulPromo}
          premiumAndDelta={insurancePrice?.premiumAndDelta}
          premiumAndDeltaPromo={insurancePrice?.premiumAndDeltaPromo}
          isLoading={isLoading}
          isPricesLoading={isPricesLoading}
          onChange={updatePromoCode}
          onSubmit={submitPage}
        />
      </SectionContainer>
      <SectionContainer marginBottom={8}>
        <InsurancePriceCard
          title={t('COMMON:labels.insurancePrice') || ''}
          isLoading={isPricesLoading || !!rangeInputError}
          premiumAndDelta={
            insurancePrice?.premiumAndDeltaPromo
              ? insurancePrice?.premiumAndDeltaPromo
              : insurancePrice?.premiumAndDelta
          }
        />
      </SectionContainer>
    </Container>
  );
};
