/* eslint-disable prettier/prettier */
import type { FC } from 'react';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { Skeleton } from '@src/components';
import {
  insurancePersonDefaultData,
  insuranceProductsCode,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import {
  DraftActionTypes,
  SportNSActionTypes,
  SportNSStoreState,
  Store,
} from '@src/store';
import { InsuranceProductType } from '@src/store/sport/sport-ns-store.types';
import { cleartDraft, getDraft } from '@src/utils';

import { SportNSForm } from './sport-ns-form';

export const SportNSPage: FC = () => {
  const {
    state: {
      stateDraft: { checkDraft },
      stateUser: { selectedProduct },
    },
    dispatch,
  } = useContext(Store);

  const [isWait, setIsWait] = useState(true);

  const {
    isLoading: productIsLoading,
    error: productError,
    res: resProduct,
    refetch: productRefetch,
  } = useRequest(
    'formSportNSFlatGetInsuranceProduct',
    'post',
    '/v1/references/get-insurance-product',
    {
      productCode: insuranceProductsCode.sport,
    }
  );

  useLayoutEffect(() => {
    if (!productIsLoading && resProduct) {
      const data: InsuranceProductType = { ...resProduct };

      dispatch({
        type: SportNSActionTypes.SetInsuranceProduct,
        payload: data || undefined,
      });
    }
  }, [productIsLoading, resProduct]);

  useEffect(() => {
    if (
      checkDraft &&
      selectedProduct &&
      !productIsLoading &&
      resProduct 
    ) {
      dispatch({
        type: DraftActionTypes.SetCheckDraft,
        payload: false,
      });
      const { resData } = getDraft();

      if (resData) {
        if (
          resData.common.productType === resProduct.code 
        ) {
          const state = resData.state as unknown as SportNSStoreState;
          dispatch({
            type: SportNSActionTypes.SetInsuranceProduct,
            payload: state?.insuranceProduct,
          });
          dispatch({
            type: SportNSActionTypes.SetInsuranceProductDate,
            payload: state?.insuranceProductDate,
          });
          dispatch({
            type: SportNSActionTypes.SetInsuranceProductStartDate,
            payload:
              state?.selectedStartDate && state?.selectedStartDate !== null
                ? new Date(state?.selectedStartDate as unknown as string)
                : null,
          });
          dispatch({
            type: SportNSActionTypes.SetInsuranceProductEndDate,
            payload:
              state?.selectedEndDate && state?.selectedEndDate !== null
                ? new Date(state?.selectedEndDate as unknown as string)
                : null,
          });
          dispatch({
            type: SportNSActionTypes.SetNumberInsurePersons,
            payload: state?.numberInsurePersons || 1,
          });
          dispatch({
            type: SportNSActionTypes.SetPromoCode,
            payload: state?.promoCode,
          });
          dispatch({
            type: SportNSActionTypes.SetSportKinds,
            payload: state?.sportKinds,
          });
          dispatch({
            type: SportNSActionTypes.SetChoosedSportKinds,
            payload: state?.choosedSportKinds,
          });
          dispatch({
            type: SportNSActionTypes.SetRisks,
            payload: state?.risks,
          });
          dispatch({
            type: SportNSActionTypes.SetSublimit,
            payload: state?.sublimit,
          });
          dispatch({
            type: SportNSActionTypes.SetInsuranceSum,
            payload: state?.insuranceSum,
          });
          dispatch({
            type: SportNSActionTypes.SetInsurancePrice,
            payload: state?.insurancePrice,
          });
          dispatch({
            type: SportNSActionTypes.SetInsurePersons,
            payload: state?.insurePersons || insurancePersonDefaultData,
          });
          dispatch({
            type: SportNSActionTypes.SetSelectedDuration,
            payload: state?.selectedDuration || null,
          });
          setIsWait(false);
        } else {
          cleartDraft();
          setIsWait(false);
        }
      }
    }
    if (!checkDraft) {
      setIsWait(false);
    }
  }, [checkDraft, selectedProduct, productIsLoading, resProduct]);

  if (isWait || productIsLoading) return <Skeleton />;

  if (productError) {
    return (
      <GlobalErrorInfo
        pending={productIsLoading}
        retrayHandler={productRefetch}
      />
    );
  }

  return <BaseLayout><SportNSForm /></BaseLayout>;
};
