import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';

import { Container, HeaderLarge3, TextSub3 } from '@src/components';
import { useHandlePressKey } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { InsuranceSubProduct, KeyCode } from '@src/types';

export const IFLSumDescriptions: FC<{ data?: InsuranceSubProduct[] }> = ({
  data,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useHandlePressKey(KeyCode.ENTER, () => navigate(-1));

  const Footer = () => (
    <>
      <Button
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
        label={t('COMMON:buttons.understandably') || ''}
      />
    </>
  );

  return (
    <BaseLayout footer={<Footer />}>
      <Container>
        {data?.map((item) => (
          <div key={item.code}>
            <HeaderLarge3>{item.name}</HeaderLarge3>
            <TextSub3>{item.description}</TextSub3>
          </div>
        ))}
      </Container>
    </BaseLayout>
  );
};
