import { utcToZonedTime } from 'date-fns-tz';

import { TIME_ZONE_MOSCOW } from '@src/constants';

export const convertDateByTimeZone = (
  date: Date | null,
  timeZone?: string
): Date | null => {
  return date instanceof Date
    ? utcToZonedTime(date, timeZone || TIME_ZONE_MOSCOW)
    : date;
};
