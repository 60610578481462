import { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Product } from '@src/constants';
import { Store } from '@src/store';
import {
  UserActionTypes,
  UserProfileDataTypes,
} from '@src/store/user/user-store.types';

interface useNavigateToPersonalInfoProps {
  status: number;
  responseURL: string;
  profile: UserProfileDataTypes | undefined;
}

export const useNavigateToPersonalInfo = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    state: {
      stateWizard: { currentStep, lastStep },
      stateUser: { selectedProduct },
    },
    dispatch,
  } = useContext(Store);

  return useCallback(
    (data: useNavigateToPersonalInfoProps) => {
      const { status, responseURL, profile } = data;
      if (status === 200 && responseURL.includes('get-profile')) {
        const birthDate = profile?.profile?.birthDate;

        dispatch({
          type: UserActionTypes.SetProfile,
          payload: profile,
        });

        if (selectedProduct === Product.PETS && birthDate) {
          return true;
        }

        if (!birthDate) {
          navigate('/personal-info', {
            state: {
              nextRoute: currentStep <= lastStep ? pathname : '/order-detail',
              isUpdateProfile: true,
              ...(currentStep !== lastStep && {
                nextStep: currentStep + 1,
              }),
            },
          });
        }
      }
    },
    [currentStep, lastStep, selectedProduct, pathname, navigate]
  );
};
