/* eslint-disable indent */
import React, { VFC, memo } from 'react';

import { NewRadio, NewRadioGroup } from '@pulse-web-ui/new-radio-group';
import { SubscriptionCard } from '@pulse-web-ui/subscription-card';

import { PaymentPeriodsListWrapper, RadioLabelRender } from '@src/components';
import { FeatureFlags } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';
import {
  PetsRadioDescRender,
  PetsRadioLabelRender,
} from '@src/pages/pets-form/components';
import { StyledSubscriptionCardGroup } from '@src/pages/pets-form/pets-payment-period.styles';

import {
  getPetsPaymentPeriodDescription,
  getPetsSubscriptionTypeLabels,
} from '../../utils';
import { PetsPaymentPeriodListProps } from './pets-payment-period-list.types';

export const PetsPaymentPeriodList: VFC<PetsPaymentPeriodListProps> = memo(
  ({ onRadioSelector, selectedDuration, getPrices }) => {
    const {
      res: [isFeatureSubscriptionType],
    } = useFeatureFlags([FeatureFlags.PetsSubscriptionType]);
    const petsSubscriptionTypeLabels = getPetsSubscriptionTypeLabels();

    return isFeatureSubscriptionType ? (
      <StyledSubscriptionCardGroup
        name="duration"
        onChange={onRadioSelector}
        selectedValue={selectedDuration}
      >
        {getPrices?.prices &&
          getPrices?.prices.map((item) => (
            <SubscriptionCard
              key={`${item.duration}-key`}
              value={item.duration}
              title={PetsRadioLabelRender(item)}
              subTitle={petsSubscriptionTypeLabels.get(item.duration)?.subTitle}
              description={PetsRadioDescRender(item)}
              discount={!!Number(item.discount) && `-${item.discount}%`}
            />
          ))}
      </StyledSubscriptionCardGroup>
    ) : (
      <PaymentPeriodsListWrapper>
        <NewRadioGroup
          name="duration"
          onChange={onRadioSelector}
          selectedValue={selectedDuration}
        >
          {getPrices?.prices &&
            getPrices?.prices.map((item) => (
              <NewRadio
                key={`${item.duration}-key`}
                value={item.duration}
                label={RadioLabelRender(item)}
                description={getPetsPaymentPeriodDescription(item)}
              />
            ))}
        </NewRadioGroup>
      </PaymentPeriodsListWrapper>
    );
  }
);
