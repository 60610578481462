import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { axiosInstance, refreshToken } from '@src/utils';

import { useNavigateToPersonalInfo } from './use-navigate-to-personal-info';

export const useAxiosInterceptors = () => {
  const {
    state: {
      stateAuth: { authTokens },
      stateAuthFlow: { sepScrAuth, authFlowParams },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToPersonalInfo = useNavigateToPersonalInfo();

  const handleResponse = useCallback(
    (res: AxiosResponse) => {
      const refToken = res?.data?.refreshToken;

      if (refToken && refToken !== sessionStorage.getItem('refresh_token')) {
        sessionStorage.setItem('refresh_token', refToken);
      }

      navigateToPersonalInfo({
        status: res?.status,
        profile: res?.data,
        responseURL: res?.request?.responseURL,
      });

      return res;
    },
    [navigateToPersonalInfo]
  );

  const handleError = useCallback(
    async (err: AxiosError) => {
      const status = err?.response?.status;
      const data = err?.response?.data as { code: string };
      const isSepScrLogin =
        !authFlowParams ||
        authFlowParams?.partnerAuthRegType !== 'base' ||
        sepScrAuth;

      if (
        status == 401 &&
        err?.response?.request.responseURL.includes('refresh-token')
      ) {
        if (isSepScrLogin) {
          navigate('/login');
        } else {
          navigate(pathname);
        }

        throw Error('REFRESH TOKEN UNAUTHORIZED');
      }

      if (status === 401) {
        if (authTokens) {
          try {
            const result = await refreshToken();

            if (result?.data) {
              sessionStorage.setItem(
                'refresh_token',
                result.data.authorization.refreshToken
              );

              dispatch({
                type: AuthActionTypes.SetAuthTokens,
                payload: result.data,
              });

              if (err.config.headers) {
                err.config.headers[
                  'Authorization'
                ] = `Bearer ${result.data.authorization.accessToken}`;
              }
              return axiosInstance.request(err.config);
            }
          } catch (error) {
            sessionStorage.removeItem('refresh_token');

            dispatch({
              type: AuthActionTypes.SetAuthTokens,
              payload: undefined,
            });

            navigate('/login', {
              state: { nextRoute: pathname, isUnauthorized: true },
            });

            throw error;
          }
        } else {
          navigate('/login');
        }
      }

      if (status === 403 && data?.code === 'INVALID_ROLE') {
        dispatch({
          type: UserActionTypes.SetUserRegistrationData,
          payload: undefined,
        });
        navigate('/personal-info', { state: { nextRoute: pathname } });
      }

      return Promise.reject(err);
    },
    [authFlowParams, sepScrAuth, navigate, pathname, dispatch, authTokens]
  );

  const interceptor = useMemo(() => {
    return axiosInstance.interceptors.response.use(handleResponse, handleError);
  }, [handleResponse, handleError]);

  useEffect(() => {
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [interceptor]);
};
