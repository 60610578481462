/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { AuthStoreState } from './index';
import { AuthActionTypes } from './index';

export type AuthStoreReducer = (
  state: AuthStoreState,
  action: StoreActions
) => AuthStoreState;

export const authStoreReducer: AuthStoreReducer = (state, action) => {
  switch (action?.type) {
    case AuthActionTypes.SetPhoneNumber:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    case AuthActionTypes.SetDisplayPhoneNumber:
      return {
        ...state,
        displayPhoneNumber: action.payload,
      };
    case AuthActionTypes.SetAuthenticationToken:
      return {
        ...state,
        authenticationToken: action.payload,
      };
    case AuthActionTypes.SetConfirmationId:
      return {
        ...state,
        confirmationId: action.payload,
      };
    case AuthActionTypes.SetAuthorizeFailState:
      return {
        ...state,
        authorizeFailState: action.payload,
      };
    case AuthActionTypes.SetAuthorizeRefRoute:
      return {
        ...state,
        authorizeRefRoute: action.payload,
      };
    case AuthActionTypes.SetAuthTokens:
      return {
        ...state,
        authTokens: action.payload,
      };
    case AuthActionTypes.SetAuthorizeConfirmState:
      return {
        ...state,
        authorizeConfirmState: action.payload,
      };
    default:
      return state;
  }
};
