/* eslint-disable indent */
import { StoreActions } from '@src/store';

import type { WizardStoreState } from './index';
import { WizardActionTypes } from './index';

export type WizardStoreReducer = (
  state: WizardStoreState,
  action: StoreActions
) => WizardStoreState;

export const wizardStoreReducer: WizardStoreReducer = (state, action) => {
  switch (action?.type) {
    case WizardActionTypes.SetCurrentStep:
      return {
        ...state,
        currentStep: action.payload,
      };
    case WizardActionTypes.UpdateWantNextStep:
      return {
        ...state,
        wantNextStep: action.payload,
      };
    case WizardActionTypes.SetFwNavDisabled:
      return {
        ...state,
        fwNavDisabled: action.payload,
      };
    case WizardActionTypes.SetUpdateFormState:
      return {
        ...state,
        updateFormState: action.payload,
      };
    case WizardActionTypes.SetLastStep:
      return {
        ...state,
        lastStep: action.payload,
      };
    default:
      return state;
  }
};
