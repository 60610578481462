/* eslint-disable indent */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { SelectorCard } from '@pulse-web-ui/selector-card';

import { SectionContainer } from '@src/common-components/container';
import { PageTitle, SectionTitle } from '@src/common-components/headers';
import { Container, RiskWrapper, Skeleton } from '@src/components';
import { InsurancePriceCard, PromoCode, RisksAccordion } from '@src/features';
import { useHandlePricesError, useNextStep, useRequest } from '@src/hooks';
import { MiteActionTypes, Store, WizardActionTypes } from '@src/store';
import { AntimiteProgramType } from '@src/types';

export const MiteRisks = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    state: {
      stateMite: {
        promoCode,
        choosedPolicy,
        insuranceProductPolicy,
        insurancePrice,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);
  const [formPromoCode, setFormPromoCode] = useState<string | undefined>(
    promoCode
  );
  const [selectedPolicy, setSelectedPolicy] = useState<
    AntimiteProgramType | undefined
  >();

  const risksElements = useMemo(
    () =>
      selectedPolicy?.risks?.reduce(
        (acc: JSX.Element[], risk) => [
          ...acc,
          ...(risk?.riskElements?.map((riskElement) => (
            <RiskWrapper key={riskElement.code}>
              <SelectorCard
                id={riskElement.code}
                name={riskElement.code}
                label={riskElement.name}
                description={riskElement.description}
                iconPath={riskElement.imageUrl
                  ?.replace(/pics-mw/, 'pics-web')
                  .replace(/png/, 'svg')}
                readOnly
              />
            </RiskWrapper>
          )) || []),
        ],
        []
      ) || [],
    [selectedPolicy?.risks]
  );

  const { isLoading, error, res, refetch } = useRequest(
    'getInsuranceProgramsPrices',
    'post',
    '/v3/subscription/get-insurance-programs-prices',
    {
      productCode: insuranceProductPolicy?.productCode,
      promoCode: formPromoCode?.length ? formPromoCode : undefined,
    },
    [formPromoCode, authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  const validatePage = useCallback(async () => {
    return true;
  }, []);

  const submitPage = (val?: string) => {
    setFormPromoCode(val);
  };

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (formPromoCode !== promoCode) {
      dispatch({
        type: MiteActionTypes.SetPromoCode,
        payload: formPromoCode,
      });
      if (formPromoCode) {
        refetch();
      } else {
        dispatch({
          type: MiteActionTypes.SetInsurancePrice,
          payload: undefined,
        });
      }
    }
  }, [formPromoCode]);

  useEffect(() => {
    setSelectedPolicy(
      insuranceProductPolicy?.antimitePrograms?.find(
        ({ insuranceProgram }) => insuranceProgram === choosedPolicy
      )
    );
  }, [insuranceProductPolicy?.antimitePrograms]);

  useEffect(() => {
    if (!isLoading && res) {
      const choosedProgramPrice = res?.programPrices?.find(
        (programPrice: { program: string }) =>
          programPrice.program === choosedPolicy
      );

      dispatch({
        type: MiteActionTypes.SetInsurancePrice,
        payload: choosedProgramPrice,
      });
    }
  }, [isLoading, res]);

  useNextStep(validatePage);

  const pricesGlobalError = useHandlePricesError(error, refetch);

  if (pricesGlobalError) {
    return pricesGlobalError;
  }

  if (isLoading) return <Skeleton />;

  return (
    <Container>
      <PageTitle>{t('COMMON:headers.insuranceRisks')}</PageTitle>

      <SectionContainer>
        <SectionTitle>
          {t('MITE_RISKS:sectionHeaders.risks', {
            policy: selectedPolicy?.name,
          })}
        </SectionTitle>
        <RisksAccordion>{risksElements}</RisksAccordion>
      </SectionContainer>
      <SectionContainer>
        <PromoCode
          promoCode={promoCode}
          isSuccessfulPromo={insurancePrice?.isSuccessfulPromo}
          premiumAndDelta={insurancePrice?.premiumAndDelta}
          premiumAndDeltaPromo={insurancePrice?.premiumAndDeltaPromo}
          isPricesLoading={isLoading}
          onSubmit={submitPage}
        />
      </SectionContainer>
      <SectionContainer>
        <InsurancePriceCard
          title={t('COMMON:labels.insurancePricePerYear')}
          isLoading={isLoading}
          premiumAndDelta={
            insurancePrice?.premiumAndDeltaPromo || selectedPolicy?.basePrice
          }
        />
      </SectionContainer>
    </Container>
  );
};
