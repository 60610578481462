import { memo, useContext, useEffect } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';
import { ErrorAlertIcon } from '@pulse-web-ui/colored-icons';

import { HeaderAdaptive1 } from '@src/components';
import { Store } from '@src/store';
import { WizardActionTypes } from '@src/store/wizard';

import {
  GlobalErrorContent,
  GlobalErrorFormSub,
  GlobalErrorWrapper,
} from './global-error-info.styles';

type Props = {
  pending?: boolean;
  retrayHandler?: () => void;
};

export const GlobalErrorInfo: FC<Props> = memo(({ pending, retrayHandler }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Store);

  const handleRetry = () => {
    if (retrayHandler) {
      retrayHandler();
    }
  };

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: true,
    });
  }, []);

  return (
    <GlobalErrorWrapper>
      <ErrorAlertIcon />
      <GlobalErrorContent>
        <HeaderAdaptive1 align="center">
          {t('AUTH:headers.whenLoadingData')}
          <br />
          {t('AUTH:headers.somethingWentWrong')}
        </HeaderAdaptive1>
        <GlobalErrorFormSub>
          {t('AUTH:hints.checkConnectionQuality')}
        </GlobalErrorFormSub>
      </GlobalErrorContent>
      {retrayHandler ? (
        <Button
          disabled={pending}
          variant={pending ? 'secondary' : 'primary'}
          label={t('COMMON:buttons.tryAgain') || ''}
          onClick={handleRetry}
        />
      ) : (
        <></>
      )}
    </GlobalErrorWrapper>
  );
});
