import { useContext, useEffect, useState } from 'react';

import { Product, UseQueryStatus } from '@src/constants';
import { AuthFlowActionTypes, Store, UserActionTypes } from '@src/store';

const getPath = (
  partnerAuthRegType: string,
  stepAuth: number | undefined,
  selectedProduct: string | undefined
): string => {
  if (partnerAuthRegType !== 'base') {
    return '/personal-info';
  }

  if (!stepAuth) {
    return '/login';
  }

  if (selectedProduct) {
    return `/${selectedProduct}`;
  }

  return '';
};

export const useAuthFlow = (): {
  isLoading: boolean;
  hasError: boolean;
  path: string;
} => {
  const {
    state: {
      stateAuthFlow: { authFlowParams, status },
      stateUser: { selectedProduct },
    },
    dispatch,
  } = useContext(Store);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [path, setPath] = useState('');

  useEffect(() => {
    if (status === UseQueryStatus.IDLE) {
      setIsLoading(false);
    }

    if (status === UseQueryStatus.ERROR) {
      setHasError(true);
      setIsLoading(false);
    }

    if (status === UseQueryStatus.SUCCESS) {
      setIsLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (authFlowParams) {
      const { partnerAuthRegType, sepScrAuth, stepAuth, product } =
        authFlowParams;

      setPath(getPath(partnerAuthRegType, stepAuth, selectedProduct));

      if (stepAuth > 0) {
        dispatch({
          type: AuthFlowActionTypes.SetAuthStep,
          payload: stepAuth,
        });
      }

      if (product) {
        dispatch({
          type: UserActionTypes.SetSelectedProduct,
          payload: product as Product,
        });
      }

      dispatch({
        type: UserActionTypes.SetIsPureSubscription,
        payload: partnerAuthRegType !== 'base',
      });

      dispatch({
        type: AuthFlowActionTypes.SetSepScrAuth,
        payload: sepScrAuth,
      });
    }
  }, [authFlowParams, selectedProduct]);

  return { isLoading, hasError, path };
};
