/* eslint-disable indent */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { FRACTION_TWO } from '@src/constants';
import { Store } from '@src/store';
import { OrderItemValueType } from '@src/types';
import {
  currencyRuLocaleWithoutFraction,
  getDayDeclensions,
  getDaysDuration,
  getPersonDeclensions,
  numFormat,
  numWord,
} from '@src/utils';
import { fullNewOrderDate } from '@src/utils/full-new-order-date';

import {
  OrderItemSubValue,
  OrderItemValue,
  OrderItemWrapper,
} from '../../order/components/components.styles';

const newDateWithoutYear = (date: Date) =>
  date.toLocaleDateString('ru-RU', {
    month: 'long',
    day: 'numeric',
  });

const getStartDate = (
  selectedStartDate: Date,
  selectedEndDate: Date
): string => {
  if (selectedStartDate.getFullYear() !== selectedEndDate.getFullYear()) {
    return fullNewOrderDate(selectedStartDate).replace('●', '');
  }

  return newDateWithoutYear(selectedStartDate);
};

export const useSportNsOrderDataFormatting = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { promoCodeFailedMessage },
      stateFormNSSport: {
        selectedStartDate,
        selectedEndDate,
        choosedSportKinds,
        risks,
        insurancePrice,
        insuranceSum,
        numberInsurePersons,
        insurePersons,
        promoCode,
      },
      stateAuth: { displayPhoneNumber },
    },
  } = useContext(Store);
  const dayDeclensions = getDayDeclensions();
  const personDeclensions = getPersonDeclensions();

  const remainingDays = getDaysDuration(
    selectedStartDate as Date,
    selectedEndDate as Date
  );

  const label = `${currencyRuLocaleWithoutFraction(
    Number(
      insurancePrice?.premiumAndDeltaPromo
        ? insurancePrice?.premiumAndDeltaPromo
        : insurancePrice?.premiumAndDelta
    ),
    FRACTION_TWO
  )} ₽`;

  const orderInsureComponent = (
    <OrderItemWrapper>
      {insurePersons.map(({ firstName, lastName, middleName, birthDate }) => (
        <div>
          <OrderItemValue>
            {lastName} {firstName} {middleName}
          </OrderItemValue>
          {birthDate && (
            <OrderItemSubValue>
              {new Date(birthDate).toLocaleDateString('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </OrderItemSubValue>
          )}
        </div>
      ))}
    </OrderItemWrapper>
  );

  const checkForFailedPromoCode = () => {
    if (promoCodeFailedMessage) {
      return t('COMMON:errors.promoCodeNotApplied');
    }
    return insurancePrice?.isSuccessfulPromo === undefined ||
      insurancePrice?.isSuccessfulPromo === null
      ? ''
      : insurancePrice?.isSuccessfulPromo === false && !promoCode
      ? ''
      : t('COMMON:errors.promoCodeNotApplied');
  };

  const orderArray = [
    {
      type: OrderItemValueType.SIMPLE,
      title: t('COMMON:labels.insurancePrice'),
      value: label,
      successValue: insurancePrice?.isSuccessfulPromo
        ? t('COMMON:success.promoCodeApplied')
        : '',
      failedValue: checkForFailedPromoCode(),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('COMMON:labels.validity'),
      value: `${getStartDate(
        selectedStartDate || new Date(),
        selectedEndDate || new Date()
      )} - ${fullNewOrderDate(
        selectedEndDate || new Date()
      )} ${remainingDays} ${numWord(remainingDays, dayDeclensions)} `,
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('NS_FORM:labels.KindsOfSports'),
      value: choosedSportKinds?.map((item) => item.label)?.join(', '),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('COMMON:labels.insuranceRisks'),
      value: risks?.map(({ name }) => name).join(', '),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('NS_FORM:labels.amountOfCoverage'),
      value: `${numFormat(insuranceSum || 0)} ₽`,
    },
    {
      type:
        numberInsurePersons > 1
          ? OrderItemValueType.ACCORDION
          : OrderItemValueType.SIMPLE,
      title:
        numberInsurePersons > 1
          ? t('COMMON:labels.insuredPersons')
          : t('COMMON:labels.insuredPerson'),
      value:
        numberInsurePersons > 1
          ? orderInsureComponent
          : `${insurePersons[0].lastName} ${insurePersons[0].firstName} ${insurePersons[0]?.middleName}`,
      ...(numberInsurePersons > 1
        ? {
            accordionTitle:
              numberInsurePersons > 1
                ? `${numberInsurePersons} ${numWord(
                    numberInsurePersons,
                    personDeclensions
                  )}`
                : false,
          }
        : {
            secondsValue: insurePersons[0].birthDate
              ? new Date(insurePersons[0].birthDate).toLocaleDateString(
                  'ru-RU',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )
              : '',
          }),
    },
    {
      type: OrderItemValueType.SIMPLE,
      title: t('COMMON:labels.phone'),
      value: displayPhoneNumber,
    },
  ];

  return {
    orderArray,
  };
};
