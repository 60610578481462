import { useContext, useEffect } from 'react';

import { Store } from '@src/store';
import { setDraftLS } from '@src/utils';

export const useIflHouseDraft = () => {
  const {
    state: {
      stateFormIFLHouse: {
        selectedIProduct,
        selectedRegion,
        regions,
        risks,
        insuranceSubproducts,
        insuranceSum,
        getPrices,
        getPricesFull,
        selectedDuration,
        selectedDate,
        dadaValue,
        promoCode,
        promoCodeApplyed,
        risksPage,
        materials,
        selectedBuildingMaterialCode,
        fieldWithEmptyDependencies,
        emptyDependencies,
        isObjectDataError,
        getSubobjectsRefetchUid,
        isSelectedCustomStreet,
        isSelectedCustomHouse,
        isEmptyStreetSuggestions,
        isEmptyHouseSuggestions,
        presetData,
      },
      stateFormIFLHouse,
      stateUser: { profile, selectedProduct },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    if (!presetData) {
      const data = {
        dataObject: stateFormIFLHouse,
        profile,
        selectedProduct,
        currentStep: currentStep || 0,
      };

      setDraftLS(data);
    }
  }, [
    selectedIProduct,
    selectedRegion,
    regions,
    risks,
    insuranceSubproducts,
    insuranceSum,
    getPrices,
    getPricesFull,
    selectedDuration,
    selectedDate,
    dadaValue,
    promoCode,
    promoCodeApplyed,
    risksPage,
    materials,
    selectedBuildingMaterialCode,
    fieldWithEmptyDependencies,
    emptyDependencies,
    isObjectDataError,
    getSubobjectsRefetchUid,
    isSelectedCustomStreet,
    isSelectedCustomHouse,
    isEmptyStreetSuggestions,
    isEmptyHouseSuggestions,
    presetData,
  ]);

  return;
};
