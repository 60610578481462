import { YMaps } from '@pbe/react-yandex-maps';
import type { FC } from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { StepWizardForm } from '@src/features';
import { useResetFlatSteps } from '@src/hooks';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

import { FormInsuranceAdditionalRisks } from './form-insurance-additional-risks';
import { FormInsuranceAddress } from './form-insurance-address';
import { FormInsurancePeriod } from './form-insurance-period';
import { FormInsuranceRisks } from './form-insurance-risks';
import { FormInsuranceSum } from './form-insurance-sum';
import { useAnalyticsIfl } from './hooks';

export const IFLFlatForm: FC = () => {
  const {
    state: {
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();

  useAnalyticsIfl();

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      navigate('/login');
    } else {
      navigate(-1);
    }
  };

  return (
    <YMaps>
      <StepWizardForm
        maxStep={5}
        useResetSteps={useResetFlatSteps}
        goBack={goBack}
      >
        {currentStep === 1 && <FormInsuranceAddress />}
        {currentStep === 2 && <FormInsuranceRisks />}
        {currentStep === 3 && <FormInsuranceAdditionalRisks />}
        {currentStep === 4 && <FormInsuranceSum />}
        {currentStep === 5 && <FormInsurancePeriod />}
      </StepWizardForm>
    </YMaps>
  );
};
