import { useEffect, useState } from 'react';

interface BaseSublimit {
  code: string;
  defaultInsuranceSum: string;
  maxLimit: string | number;
  minLimit: string | number;
}

interface Params<T> {
  handleMergedSublimits: (sublimits: T[]) => void;
  firstSublimits: T[] | undefined;
  secondSublimits: T[] | undefined;
}

export const useMergeSublimits = <T extends BaseSublimit>({
  handleMergedSublimits,
  firstSublimits,
  secondSublimits,
}: Params<T>) => {
  const [hasLimitsChanged, setHasLimitsChanged] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (
      !!firstSublimits &&
      !!secondSublimits &&
      hasLimitsChanged === undefined
    ) {
      const { hasChanged, sublimits } = firstSublimits.reduce(
        (acc: { hasChanged: boolean; sublimits: T[] }, firstSublimitsItem) => {
          const secondSublimitItem = secondSublimits.find(
            ({ code }) => code === firstSublimitsItem.code
          );
          if (secondSublimitItem) {
            if (!acc.hasChanged) {
              acc.hasChanged =
                Number(secondSublimitItem.defaultInsuranceSum) >
                Number(firstSublimitsItem.maxLimit);
            }

            acc.sublimits.push({
              ...secondSublimitItem,
              defaultInsuranceSum:
                Number(secondSublimitItem.defaultInsuranceSum) >
                Number(firstSublimitsItem.maxLimit)
                  ? firstSublimitsItem.maxLimit.toString()
                  : secondSublimitItem.defaultInsuranceSum,
              maxLimit: firstSublimitsItem.maxLimit,
              minLimit: firstSublimitsItem.minLimit,
            });
          } else {
            acc.sublimits.push(firstSublimitsItem);
          }

          return acc;
        },
        {
          hasChanged: false,
          sublimits: [],
        }
      );

      setHasLimitsChanged(hasChanged);

      if (hasChanged) {
        handleMergedSublimits(sublimits);
      }
    }
  }, [
    firstSublimits,
    secondSublimits,
    handleMergedSublimits,
    hasLimitsChanged,
  ]);
};
