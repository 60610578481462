/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Datepicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Info } from '@pulse-web-ui/icons';
import { Input } from '@pulse-web-ui/input';
import { NewRadio, NewRadioGroup } from '@pulse-web-ui/new-radio-group';
import { useTheme } from '@pulse-web-ui/theme';

import {
  Container,
  FormLabel,
  FormSubTitle,
  InfoWrapper,
  RadioDescriptionRender,
  RadioLabelRender,
  Skeleton,
  WarnigText,
  WarnigWrapper,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  PRODUCT_VERSION_ONE,
  analyticEvents,
  durationDescription,
  insuranceProductsCode,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useHandlePressKey, useNextStep } from '@src/hooks';
import { useRequests } from '@src/hooks/use-requests';
import { formInsurancePeriodSchema } from '@src/schemas';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { FormPromoProps, KeyCode, SelectedDuration } from '@src/types';
import { checkDateBefore, convertDateByTimeZone } from '@src/utils';

import {
  ContentWrapper,
  PromoSubmitButton,
  PromoTextInput,
  PromoWrapper,
} from './form-ns-period.styles';
import { useNsDraft } from './hooks';

export const FormNsPeriod: FC = () => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const {
    state: {
      stateFormNS: {
        insuranceSum,
        insuranceProduct,
        selectedMainRisks = [],
        selectedAdditionalRisks = [],
        numberInsurePersons: { numberChildren, numberAdults, numberElderly },
        getPrices,
        selectedDate,
        selectedDuration,
        subscriptionDateLimit,
        promoCode: statePromoCode,
        promoCodeApplyed,
      },
      stateAuth: { authTokens },
      stateUser: { promoCodeFailedMessage },
    },
    dispatch,
  } = useContext(Store);
  const navigate = useNavigate();
  const [dateEqual, setDateEqual] = useState<boolean>(false);
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const [autoInitPromo, setAutoInitPromo] = useState(false);
  const [localPromo, setLocalPromo] = useState<string | undefined>();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      promoCode:
        promoCodeApplyed && getPrices?.isSuccessfulPromo
          ? promoCodeApplyed
          : statePromoCode,
    },
  });

  const persons = useMemo(
    () => [
      ...Array(numberChildren).fill({ ageMin: '1' }),
      ...Array(numberAdults).fill({ ageMin: '18' }),
      ...Array(numberElderly).fill({ ageMin: '70' }),
    ],
    [numberChildren, numberAdults, numberElderly]
  );

  const risks = useMemo(
    () => [...selectedMainRisks, ...selectedAdditionalRisks],
    [selectedMainRisks, selectedAdditionalRisks]
  );

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const validatePage = useCallback(() => {
    return true;
  }, []);
  useNextStep(validatePage);
  useNsDraft();

  const setSelectedDate = (newDate: Date) => {
    setStepUpdated();
    dispatch({
      type: NSActionTypes.SetSelectedDate,
      payload: newDate,
    });
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
    sendAnalyticEvent(analyticEvents.dateStartAccident);
  };

  useEffect(() => {
    if (
      selectedDate &&
      subscriptionDateLimit &&
      subscriptionDateLimit &&
      (checkDateBefore(
        new Date(selectedDate),
        new Date(subscriptionDateLimit.startDate)
      ) ||
        checkDateBefore(
          new Date(subscriptionDateLimit.endDate),
          new Date(selectedDate)
        ) ||
        dateEqual)
    ) {
      setSelectedDate(new Date(subscriptionDateLimit.startDate));
    }
  }, [subscriptionDateLimit, dateEqual]);

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch({
        type: NSActionTypes.SetPromoCode,
        payload: value.promoCode,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onRadioSelector = (val: SelectedDuration) => {
    setStepUpdated();

    dispatch({
      type: NSActionTypes.SetSelectedDuration,
      payload: val,
    });

    sendAnalyticEvent(analyticEvents.periodSelectAccident, {
      period: durationDescription[val],
    });
  };

  const {
    res: [getPricesData, getInsuranceLimitStartData],
    isFetching,
    isError,
    refetchAll,
  } = useRequests([
    {
      key: 'formPeriodNSGetPrices',
      method: 'post',
      url: '/v1/subscription/get-prices',
      request: {
        risks,
        productCode: insuranceProduct?.code,
        insuranceSum: Number(insuranceSum),
        returnMinDuration: false,
        accidents: {
          persons,
        },
        promoCode:
          authTokens?.authorization?.accessToken && autoInitPromo
            ? localPromo
            : undefined,
      },
      deps: [
        insuranceSum,
        persons,
        localPromo,
        autoInitPromo,
        risks,
        authTokens?.authorization?.accessToken,
      ],
      accessToken: authTokens?.authorization?.accessToken,
    },
    {
      key: 'formNSGetInsuranceLimitStart',
      method: 'get',
      url: `/v3/references/insurance-limit-start/${insuranceProductsCode.ns}/${PRODUCT_VERSION_ONE}`,
      deps: [
        insuranceSum,
        persons,
        risks,
        authTokens?.authorization?.accessToken,
      ],
    },
  ]);

  const handleKeyPressEnter = () => {
    if (isInputActive) {
      submitPromoCode();
    } else {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };

  useEffect(() => {
    isFetching && setIsInputActive(false);
  }, [isFetching]);

  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [
    isFetching,
    isInputActive,
  ]);

  useEffect(() => {
    if (!isFetching && getPricesData) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: NSActionTypes.SetGetPrices,
        payload: getPricesData.data,
      });

      if (
        selectedDuration === null ||
        !getPricesData?.data?.prices?.some(
          (price: { duration: string }) => price.duration === selectedDuration
        )
      ) {
        dispatch({
          type: NSActionTypes.SetSelectedDuration,
          payload: getPricesData?.data?.prices[0]?.duration,
        });
      }
      if (
        getPricesData?.data?.isSuccessfulPromo === false &&
        statePromoCode !== ''
      ) {
        setError('promoCode', {
          type: 'string',
          message: 'COMMON:errors.promoCodeNotValid',
        });

        dispatch({
          type: UserActionTypes.SetPromoCodeFailedMessage,
          payload: t('COMMON:errors.promoCodeNotValid') || '',
        });

        dispatch({
          type: NSActionTypes.SetPromoCodeApplyed,
          payload: undefined,
        });
      }
    }

    const isPromoCodeAppliedSuccessfully =
      getPricesData.data?.isSuccessfulPromo && statePromoCode !== '';

    let isButtonHasToBeDisabled = true;

    if (errors.promoCode) {
      isButtonHasToBeDisabled = false;
    }

    if (isPromoCodeAppliedSuccessfully) {
      isButtonHasToBeDisabled = true;
    }

    if (
      getPricesData?.data?.isSuccessfulPromo === false &&
      statePromoCode !== ''
    ) {
      isButtonHasToBeDisabled = false;
    }

    if (
      getPricesData?.data?.isSuccessfulPromo === undefined &&
      statePromoCode !== '' &&
      !autoInitPromo
    ) {
      isButtonHasToBeDisabled = false;
    }

    if (
      getPricesData?.data?.isSuccessfulPromo &&
      statePromoCode !== '' &&
      !promoCodeApplyed
    ) {
      dispatch({
        type: NSActionTypes.SetPromoCodeApplyed,
        payload: statePromoCode,
      });
    }

    setSubmitPromoDisabled(isButtonHasToBeDisabled);
  }, [isFetching, getPricesData?.data]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    if (promoCodeApplyed && getPrices?.isSuccessfulPromo) {
      setLocalPromo(promoCodeApplyed);
      setAutoInitPromo(true);
    }
  }, []);

  useEffect(() => {
    if (
      !isFetching &&
      getInsuranceLimitStartData &&
      getInsuranceLimitStartData.data?.startDate
    ) {
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });

      dispatch({
        type: NSActionTypes.SetSubscriptionDateLimit,
        payload: getInsuranceLimitStartData.data,
      });
      if (
        JSON.stringify(getInsuranceLimitStartData.data?.startDate) ===
        JSON.stringify(getInsuranceLimitStartData.data?.endDate)
      ) {
        setDateEqual(true);
      } else setDateEqual(false);
      setSelectedDate(
        selectedDate ?? new Date(getInsuranceLimitStartData.data?.startDate)
      );
    }
  }, [isFetching, getInsuranceLimitStartData.data]);

  useEffect(() => {
    if (selectedDate !== undefined) {
      localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
    }
  }, [selectedDate]);

  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === statePromoCode);
  };

  const submitPromoCode = handleSubmit((data) => {
    dispatch({
      type: UserActionTypes.SetPromoCodeFailedMessage,
      payload: undefined,
    });
    dispatch({
      type: NSActionTypes.SetPromoCode,
      payload: data.promoCode,
    });
    dispatch({
      type: NSActionTypes.SetPromoCodeApplyed,
      payload: undefined,
    });
    setAutoInitPromo(true);
    setLocalPromo(statePromoCode);
  });

  if (isFetching) return <Skeleton />;

  if (isError) {
    const e = (isError as any)?.response?.status;
    if (e === 401) {
      dispatch({
        type: AuthActionTypes.SetAuthorizeFailState,
        payload: {
          title: t('COMMON:errors.authorizationError'),
          subtitle: t('COMMON:errors.retryRegistration'),
          refRoute: '/personal-info',
        },
      });

      dispatch({
        type: WizardActionTypes.SetCurrentStep,
        payload: 1,
      });

      navigate('/authorize-fail');
    }
    return <GlobalErrorInfo pending={isFetching} retrayHandler={refetchAll} />;
  }

  return (
    <Container>
      <FormLabel marginBottom={24}>
        {t('COMMON:headers.paymentOfPeriod')}
      </FormLabel>
      {getPrices && subscriptionDateLimit && selectedDate && (
        <>
          <ContentWrapper>
            {getPrices?.prices && (
              <NewRadioGroup
                name="duration"
                onChange={onRadioSelector}
                selectedValue={selectedDuration}
              >
                {getPrices?.prices &&
                  getPrices?.prices.map((item) => (
                    <NewRadio
                      key={`${item.duration}-key`}
                      value={item.duration}
                      label={RadioLabelRender(item)}
                      description={RadioDescriptionRender(item)}
                    />
                  ))}
              </NewRadioGroup>
            )}
          </ContentWrapper>
          <ContentWrapper>
            <PromoWrapper>
              <PromoTextInput>
                <Controller
                  control={control}
                  name="promoCode"
                  render={({ field: { onChange, value }, fieldState }) => (
                    <HelperText
                      status={
                        fieldState.error || promoCodeFailedMessage
                          ? 'error'
                          : 'success'
                      }
                      message={
                        (errors.promoCode?.message &&
                          t(errors.promoCode.message)) ||
                        promoCodeFailedMessage ||
                        (getPricesData.data?.isSuccessfulPromo &&
                        statePromoCode !== ''
                          ? t('COMMON:hints.discountForFirstPeriod')
                          : '')
                      }
                    >
                      <Input
                        label={t('COMMON:labels.promoCode') || ''}
                        value={value}
                        onChange={(val) => {
                          onChange(val);
                          checkSubmitState(val);
                        }}
                        onFocus={() => setIsInputActive(true)}
                        onBlur={() => setIsInputActive(false)}
                        error={!!errors.promoCode || !!promoCodeFailedMessage}
                        disabled={isFetching}
                      />
                    </HelperText>
                  )}
                />
              </PromoTextInput>
              <PromoSubmitButton>
                <Button
                  label={t('COMMON:success.apply') || ''}
                  onClick={submitPromoCode}
                  disabled={submitPromoDisabled}
                  variant="secondary-2"
                  adaptiveWidth
                />
              </PromoSubmitButton>
            </PromoWrapper>
          </ContentWrapper>

          <FormSubTitle>{t('COMMON:labels.subscriptionActive')}</FormSubTitle>
          <HelperText message="" status="default" messagePosition="up">
            {selectedDate &&
            subscriptionDateLimit.startDate &&
            subscriptionDateLimit.endDate ? (
              dateEqual ? (
                <InfoWrapper>
                  <Info width={24} color={theme.colors.icon.primary} />
                  <WarnigText>
                    {t('COMMON:labels.subscriptionWillStart')}{' '}
                    {convertDateByTimeZone(
                      subscriptionDateLimit.startDate
                    )?.toLocaleDateString('ru-RU', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </WarnigText>
                </InfoWrapper>
              ) : (
                <Datepicker
                  onChange={setSelectedDate}
                  selected={selectedDate}
                  minDate={convertDateByTimeZone(
                    new Date(subscriptionDateLimit.startDate)
                  )}
                  maxDate={convertDateByTimeZone(
                    new Date(subscriptionDateLimit.endDate)
                  )}
                />
              )
            ) : null}
          </HelperText>
        </>
      )}
    </Container>
  );
};
