/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ReactDatePicker } from '@pulse-web-ui/datepicker';
import { HelperText } from '@pulse-web-ui/helper-text';

import {
  Container,
  FormLabel,
  NumericInputBox,
  NumericInputLabel,
  NumericInputWrapper,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  PRODUCT_VERSION_ONE,
  analyticEvents,
  insurancePersonDefaultData,
  insuranceProductsCode,
  sportNsRoute,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequest } from '@src/hooks';
import {
  AuthActionTypes,
  SportNSActionTypes,
  Store,
  WizardActionTypes,
} from '@src/store';
import { InsuranceProductDateType } from '@src/store/sport/sport-ns-store.types';
import { InsurePerson } from '@src/types';
import { convertDateByTimeZone } from '@src/utils';

import { useSportDraft } from './hooks';
import {
  CalendarContainer,
  StyledDateText,
  StyledDateTextMobile,
  StyledDatepicker,
  StyledDatepickerBox,
  StyledNumericInput,
  StyledText,
} from './sport-form.styles';
import { getDays } from './utils/get-min-days';

export const FormSportNSQuantity = (): ReactElement => {
  const firstDatepickerRef = useRef<ReactDatePicker>(null);
  const secondDatepickerRef = useRef<ReactDatePicker>(null);

  const {
    state: {
      stateFormNSSport: {
        numberInsurePersons,
        insurePersons,
        insuranceProduct,
        insuranceProductDate,
        selectedStartDate,
        selectedEndDate,
      },
      stateWizard: { wantNextStep, updateFormState },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const [selectPersonsError, setSelectPersonsError] = useState(false);
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndStartDateError] = useState<string>('');

  const sportSchema = yup.object({
    minDate: yup
      .string()
      .required(t('SPORT_FORM:errors.startDateOfPolicy') || '')
      .nullable(),
    maxDate: yup
      .string()
      .required(t('SPORT_FORM:errors.policyExpirationDate') || '')
      .nullable(),
  });

  const {
    res: insuranceLimitRes,
    isLoading,
    error,
    refetch,
  } = useRequest(
    'formSportNSGetLimitInsuranceProducts',
    'get',
    `/v3/references/insurance-limit-start/${insuranceProductsCode.sport}/${PRODUCT_VERSION_ONE}`
  );

  const storeInsuranceProductDate = useCallback(
    (data: InsuranceProductDateType) => {
      dispatch({
        type: SportNSActionTypes.SetInsuranceProductDate,
        payload: data || undefined,
      });
    },
    [insuranceProduct]
  );

  useEffect(() => {
    if (!isLoading && !insuranceProductDate) {
      storeInsuranceProductDate(insuranceLimitRes);
    }
  }, [isLoading]);

  const {
    control,
    formState: { errors },
    watch,
    getValues,
    setError,
    reset,
  } = useForm<any>({
    resolver: yupResolver(sportSchema),
    mode: 'all',
    defaultValues: {
      numberInsurePersons: numberInsurePersons,
      minDate: selectedStartDate,
      maxDate: selectedEndDate,
    },
  });

  const clearDateHandler = () => {
    dispatch({
      type: SportNSActionTypes.SetInsuranceProductStartDate,
      payload: null,
    });
    dispatch({
      type: SportNSActionTypes.SetInsuranceProductEndDate,
      payload: null,
    });
    reset({
      minDate: null,
      maxDate: null,
    });
  };

  const minDate = getValues('minDate')
    ? new Date(getValues('minDate'))
    : undefined;

  const storeNumberInsurePersons = useCallback((data) => {
    const newInsurePersons: InsurePerson[] = [];
    const { numberInsurePersons } = data;
    for (let i = 0; i < numberInsurePersons; i++) {
      newInsurePersons.push(insurancePersonDefaultData);
    }
    dispatch({
      type: SportNSActionTypes.SetNumberInsurePersons,
      payload: numberInsurePersons,
    });

    dispatch({
      type: SportNSActionTypes.SetInsurePersons,
      payload: newInsurePersons,
    });
  }, []);

  useEffect(() => {
    const subscription = watch(() => {
      storeNumberInsurePersons(getValues());

      dispatch({
        type: SportNSActionTypes.SetInsuranceProductStartDate,
        payload: getValues().minDate,
      });
      dispatch({
        type: SportNSActionTypes.SetInsuranceProductEndDate,
        payload: getValues().maxDate,
      });
      return;
    });
    return () => subscription.unsubscribe();
  }, [watch, insurePersons, numberInsurePersons]);

  const validatePage = () => {
    const values = getValues();
    let isTimeValid = true;

    if (startDateError.length > 0) {
      isTimeValid = false;
    }

    if (!values.minDate) {
      setError('minDate', {
        type: 'string',
        message: t('SPORT_FORM:errors.startDateOfPolicy') || '',
      });
      isTimeValid = false;
    }

    if (!values.maxDate) {
      setError('maxDate', {
        type: 'string',
        message: t('SPORT_FORM:errors.policyExpirationDate') || '',
      });

      isTimeValid = false;
    }

    const isValid =
      numberInsurePersons !== 0 &&
      !selectPersonsError &&
      isTimeValid &&
      !startDateError.length &&
      !endDateError.length;

    dispatch({
      type: SportNSActionTypes.SetInsuranceProductStartDate,
      payload: values.minDate,
    });

    dispatch({
      type: SportNSActionTypes.SetInsuranceProductEndDate,
      payload: values.maxDate,
    });

    return isValid;
  };

  useNextStep(validatePage);
  useSportDraft();

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.sportToStepCount);
  }, []);

  useEffect(() => {
    const { minDate } = getValues();

    if (insuranceLimitRes && minDate?.toString().length > 0) {
      const formattedCurrentValue = format(new Date(minDate), 'yyyy-MM-dd');
      const minDataValue = format(
        new Date(insuranceLimitRes.startDate),
        'yyyy-MM-dd'
      );
      const maxDataValue = format(
        new Date(insuranceLimitRes.endDate),
        'yyyy-MM-dd'
      );

      if (
        formattedCurrentValue === minDataValue ||
        formattedCurrentValue === maxDataValue
      ) {
        return setStartDateError('');
      }

      if (new Date(insuranceLimitRes.startDate) > new Date(minDate)) {
        return clearDateHandler();
      }

      if (new Date(insuranceLimitRes.endDate) < new Date(minDate)) {
        return clearDateHandler();
      }
      return setStartDateError('');
    }
  }, [isLoading, getValues()]);

  useEffect(() => {
    const { maxDate, minDate } = getValues();

    if (startDateError.length > 0) {
      return setEndStartDateError(
        t('SPORT_FORM:errors.unableToIssuePolicy') || ''
      );
    }
    if (startDateError.length === 0) {
      setEndStartDateError('');
    }

    if (
      insuranceLimitRes &&
      maxDate?.toString().length > 0 &&
      minDate?.toString().length > 0 &&
      !startDateError.length
    ) {
      const endDuration = new Date(minDate);

      const minEndDuration = new Date(
        endDuration.setDate(
          endDuration.getDate() + insuranceLimitRes.minDuration - 1
        )
      );

      const maxEndDuration = new Date(
        endDuration.setDate(
          endDuration.getDate() + insuranceLimitRes.maxDuration - 1
        )
      );

      if (minEndDuration > maxDate) {
        return clearDateHandler();
      }

      if (maxEndDuration < maxDate) {
        return clearDateHandler();
      }

      return setEndStartDateError('');
    }
  }, [isLoading, getValues(), startDateError]);

  useEffect(() => {
    const values = getValues();
    if (values.minDate) {
      localStorage.setItem('selectedDate', JSON.stringify(values.minDate));
    }
  }, [minDate]);

  useEffect(() => {
    const subscription = watch(() => storeNumberInsurePersons(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (wantNextStep && !numberInsurePersons) {
      setSelectPersonsError(true);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [wantNextStep]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, [updateFormState]);

  useEffect(() => {
    if (selectPersonsError && numberInsurePersons) {
      setSelectPersonsError(false);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [numberInsurePersons]);

  useEffect(() => {
    localStorage.removeItem('successText');
    localStorage.removeItem('startDate');

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: sportNsRoute,
    });
  }, []);

  const isDisabledIncrement = useMemo(() => {
    if (insuranceProduct && insuranceProduct.maxObjectsNumber !== null) {
      return numberInsurePersons >= insuranceProduct.maxObjectsNumber;
    }
    return false;
  }, [numberInsurePersons, insuranceProduct]);

  if (isLoading) return <Skeleton />;

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  return (
    <Container>
      <FormLabel>{t('SPORT_FORM:headers.numberOfInsured')}</FormLabel>
      <NumericInputWrapper>
        <NumericInputBox>
          {insuranceProduct?.maxObjectsNumber && (
            <NumericInputLabel>
              {t('SPORT_FORM:labels.maxPersons', {
                maxCount: insuranceProduct.maxObjectsNumber,
              })}
            </NumericInputLabel>
          )}
          <Controller
            control={control}
            name="numberInsurePersons"
            render={({ field }) => (
              <StyledNumericInput
                disabledIncrement={isDisabledIncrement}
                minValue={1}
                readOnly
                {...field}
              />
            )}
          />
        </NumericInputBox>
      </NumericInputWrapper>
      <FormLabel marginTop={32}>
        {t('SPORT_FORM:headers.policyPeriod')}
      </FormLabel>
      <StyledText>{t('SPORT_FORM:hints.setPeriod')}</StyledText>
      <StyledDatepickerBox>
        <Controller
          control={control}
          name={'minDate'}
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <HelperText
              status={
                fieldState.error
                  ? 'error'
                  : Boolean(startDateError.length)
                  ? 'error'
                  : 'default'
              }
              message={errors.minDate?.message || startDateError}
            >
              <StyledDatepicker
                error={!!errors.minDate || Boolean(startDateError.length)}
                selected={value}
                ref={firstDatepickerRef}
                showInput
                time={'00:00'}
                required
                label={t('SPORT_FORM:labels.startDate') || ''}
                onChange={onChange}
                onBlur={onBlur}
                minDate={convertDateByTimeZone(
                  new Date(insuranceLimitRes.startDate)
                )}
                maxDate={convertDateByTimeZone(
                  new Date(insuranceLimitRes.endDate)
                )}
                showDisabledMonthNavigation
                popperContainer={CalendarContainer}
              />
            </HelperText>
          )}
        />

        <StyledDateTextMobile>
          {t('SPORT_FORM:hints.byMoscowTime')}
        </StyledDateTextMobile>

        <Controller
          control={control}
          name={'maxDate'}
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <HelperText
              status={
                fieldState.error
                  ? 'error'
                  : Boolean(endDateError.length)
                  ? 'error'
                  : 'default'
              }
              message={
                <div style={{ maxWidth: 330 }}>
                  {errors.maxDate?.message || endDateError}
                </div>
              }
            >
              <StyledDatepicker
                error={!!errors.maxDate || Boolean(endDateError.length)}
                ref={secondDatepickerRef}
                showInput
                time={'23:59'}
                label={t('SPORT_FORM:labels.endDate') || ''}
                onChange={onChange}
                onBlur={onBlur}
                required
                minDate={getDays(
                  minDate
                    ? insuranceLimitRes.minDuration - 1
                    : insuranceLimitRes.minDuration,
                  minDate
                )}
                maxDate={getDays(
                  minDate
                    ? insuranceLimitRes.maxDuration - 1
                    : insuranceLimitRes.maxDuration,
                  minDate
                )}
                selected={value}
                showDisabledMonthNavigation
                popperContainer={CalendarContainer}
              />
            </HelperText>
          )}
        />
      </StyledDatepickerBox>

      <StyledDateText>{t('SPORT_FORM:hints.byMoscowTime')}</StyledDateText>
    </Container>
  );
};
