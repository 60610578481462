import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';

import { Container } from '@src/components';
import { BaseLayout } from '@src/layouts/base-layout';
import { Store } from '@src/store';
import { MaterialObject } from '@src/types';

import { Material, MaterialsWrapper } from './components';

export const IFLHouseMaterialsDescriptions: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLHouse: { materials },
    },
  } = useContext(Store);

  const navigate = useNavigate();

  const Footer = () => (
    <>
      <Button
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
        label={t('COMMON:buttons.understandably') || ''}
      />
    </>
  );

  return (
    <BaseLayout footer={<Footer />}>
      <Container>
        <MaterialsWrapper>
          {!materials?.length && t('COMMON:errors.noData')}
          {materials?.map((materialObject: MaterialObject) => (
            <Material
              fullDescriptionTitle={materialObject.fullDescriptionTitle}
              fullDescription={materialObject.fullDescription}
            />
          ))}
        </MaterialsWrapper>
      </Container>
    </BaseLayout>
  );
};
