import { useTranslation } from 'react-i18next';

import { Button } from '@pulse-web-ui/button';
import { PhoneClassic, Telegram, Whatsapp } from '@pulse-web-ui/icons';

import {
  SupportBlockContent,
  SupportBlockTitle,
  SupportButtonsWrapper,
} from './new-support.styles';

export const NewSupportBlock = () => {
  const { t } = useTranslation();

  return (
    <SupportBlockContent>
      <SupportBlockTitle>{t('AUTH:headers.contactSupport')}</SupportBlockTitle>
      <SupportButtonsWrapper>
        <Button
          variant="secondary"
          icon={<Telegram />}
          onClick={() => window.open('https://t.me/Pulse_insure_bot', '_blank')}
        >
          <strong>{t('COMMON:labels.telegram')}</strong>
        </Button>
        <Button
          variant="secondary"
          icon={<Whatsapp />}
          onClick={() =>
            window.open('https://wa.me/message/YV4SCMVSU53OG1', '_blank')
          }
        >
          <strong>{t('COMMON:labels.whatsApp')}</strong>
        </Button>
        <Button
          variant="secondary"
          icon={<PhoneClassic />}
          onClick={() => (window.location.href = 'tel:+78003021554')}
        >
          <strong>{t('COMMON:labels.freeCall')}</strong>
        </Button>
      </SupportButtonsWrapper>
    </SupportBlockContent>
  );
};
