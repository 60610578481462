/* eslint-disable indent */
import { Product, insurancePersonDefaultData } from '@src/constants';
import type {
  IFLFlatStoreState,
  IFLHouseStoreState,
  NSStoreState,
  PetsStoreState,
  SportNSStoreState,
} from '@src/store';
import { UserProfileDataTypes } from '@src/store/user/user-store.types';
import { CurrentStep } from '@src/store/wizard/wizard-store.state';
import { InsurePerson } from '@src/types';

type DraftProps = {
  dataObject:
    | IFLFlatStoreState
    | IFLHouseStoreState
    | NSStoreState
    | SportNSStoreState
    | PetsStoreState;
  profile?: UserProfileDataTypes;
  selectedProduct: Product | undefined;
  currentStep: CurrentStep;
};

export const setDraftLS = (props: DraftProps) => {
  const { dataObject, selectedProduct, currentStep } = props;
  const resultDate = { ...dataObject };
  let productType: string | undefined;

  switch (selectedProduct) {
    case Product.HOUSE:
      productType = (dataObject as IFLHouseStoreState).selectedIProduct?.code;
      break;
    case Product.APARTMENT:
      productType = (dataObject as IFLFlatStoreState).selectedIProduct?.code;
      break;
    case Product.NS:
      productType = (dataObject as NSStoreState).insuranceProduct?.code;
      break;
    case Product.PETS:
      productType = (dataObject as PetsStoreState).selectedIProduct?.code;
      break;
    case Product.SPORT:
      productType = (dataObject as SportNSStoreState).insuranceProduct?.code;
      break;
    // TODO: дописать реализацию для антиклеща.
    // case 'mite':
    //   productType = (dataObject as MiteStoreState).baseParameters.productCode;
    //   break;
    default:
      productType = (dataObject as IFLHouseStoreState).selectedIProduct?.code;
  }

  if (selectedProduct === Product.NS) {
    (resultDate as NSStoreState).insurePersons = [];
  }

  if (selectedProduct === Product.SPORT) {
    const newPersons: InsurePerson[] = [];
    (resultDate as SportNSStoreState).numberInsurePersons;
    let i = 0;

    for (i; i < (resultDate as SportNSStoreState).numberInsurePersons; i++) {
      newPersons.push(insurancePersonDefaultData);
    }

    (resultDate as SportNSStoreState).insurePersons = newPersons;
  }

  if (productType) {
    return localStorage.setItem(
      `${selectedProduct}`,
      JSON.stringify({
        state: resultDate,
        common: {
          // TEMP: remove userId deps

          // userId: profile?.profile.userId,
          step: currentStep,
          productType,
        },
      })
    );
  }

  return;
};
