import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AppContent } from '@src/common-components/app-content';
import {
  RoutesNavigate,
  ScrollToTop,
  ThemeContainer,
  ThemeInterceptor,
} from '@src/components';
import { WebAnalytic } from '@src/components/web-analytic';
import {
  PAYMENT_ERROR_ROUTE,
  PAYMENT_IN_PROGRESS_ROUTE,
  PAYMENT_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
  miteRoute,
  miteStepsRoute,
} from '@src/constants';
import {
  AuthorizationPage,
  AuthorizeFailPage,
  IdentificationPage,
  PersonalInfoPage,
} from '@src/pages/authorization';
import {
  BestToPayErrorPage,
  BestToPayInProgressPage,
  BestToPayStartPage,
  BestToPaySuccessPage,
} from '@src/pages/best-to-pay';
import { IFLFlatRiskDescriptions } from '@src/pages/ifl-flat-form/form-insurance-risk-descriptions';
import { IFLFlatSumDescriptions } from '@src/pages/ifl-flat-form/form-insurance-sum-descriptions';
import { IFLFlatPage } from '@src/pages/ifl-flat-form/ifl-flat-page';
import { IFLHouseMaterialsDescriptions } from '@src/pages/ifl-house-form/form-insurance-materials';
import { IFLHouseRiskDescriptions } from '@src/pages/ifl-house-form/form-insurance-risk-descriptions';
import { IFLHouseSumDescriptions } from '@src/pages/ifl-house-form/form-insurance-sum-descriptions';
import { IFLHousePage } from '@src/pages/ifl-house-form/ifl-house-page';
import { MitePage, MitePolicyChoice } from '@src/pages/mite';
import { NSPage } from '@src/pages/ns-form/ns-page';
import { OrderCheckout, OrderDetail } from '@src/pages/order';
import { PetsPage, PetsRiskDescriptions } from '@src/pages/pets-form';
import { PresetSetting } from '@src/pages/preset-setting';
import { ScoreErrorPage } from '@src/pages/score-error';
import { SportNSPage } from '@src/pages/sport-form/sport-ns-page';
import { VipPage } from '@src/pages/vip';
import { StoreProvider } from '@src/store';

import './i18n/config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => (
  <StoreProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <WebAnalytic />
        <ScrollToTop />
        <ThemeContainer>
          <AppContent>
            <ThemeInterceptor />
            <Routes>
              <Route path="/" element={<RoutesNavigate />} />
              <Route path="/house" element={<IFLHousePage />} />
              <Route path="/apartment" element={<IFLFlatPage />} />
              <Route path="/sport-ns" element={<SportNSPage />} />

              <Route
                path="/house-materials-descriptions"
                element={<IFLHouseMaterialsDescriptions />}
              />
              <Route
                path="/house-risk-descriptions"
                element={<IFLHouseRiskDescriptions />}
              />
              <Route
                path="/flat-risk-descriptions"
                element={<IFLFlatRiskDescriptions />}
              />
              <Route path="/ns" element={<NSPage />} />
              <Route path={miteStepsRoute} element={<MitePage />} />
              <Route path={miteRoute} element={<MitePolicyChoice />} />
              <Route path="/score-error" element={<ScoreErrorPage />} />
              <Route path="/login" element={<IdentificationPage />} />
              <Route path="/authorize" element={<AuthorizationPage />} />
              <Route path="/personal-info" element={<PersonalInfoPage />} />
              <Route path="/authorize-fail" element={<AuthorizeFailPage />} />
              <Route path={PAYMENT_ROUTE} element={<BestToPayStartPage />} />
              <Route
                path={PAYMENT_ERROR_ROUTE}
                element={<BestToPayErrorPage />}
              />
              <Route
                path={PAYMENT_SUCCESS_ROUTE}
                element={<BestToPaySuccessPage />}
              />
              <Route
                path={PAYMENT_IN_PROGRESS_ROUTE}
                element={<BestToPayInProgressPage />}
              />
              <Route path="/order-detail" element={<OrderDetail />} />
              <Route path="/order-checkout" element={<OrderCheckout />} />
              <Route path="/pets" element={<PetsPage />} />
              <Route
                path="/pets-risk-descriptions"
                element={<PetsRiskDescriptions />}
              />
              <Route path="/vip" element={<VipPage />} />
              <Route path="/preset-settings" element={<PresetSetting />} />
              <Route
                path="/flat-sum-descriptions"
                element={<IFLFlatSumDescriptions />}
              />
              <Route
                path="/house-sum-descriptions"
                element={<IFLHouseSumDescriptions />}
              />
            </Routes>
          </AppContent>
        </ThemeContainer>
      </BrowserRouter>
    </QueryClientProvider>
  </StoreProvider>
);

export default App;
