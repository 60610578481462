import type { FC } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';

import { Container, HeaderLarge3, TextSub3 } from '@src/components';
import { useHandlePressKey } from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { Store } from '@src/store';
import { KeyCode } from '@src/types';

export const IFLFlatRiskDescriptions: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLFlat: { risks, risksPage },
    },
  } = useContext(Store);
  const navigate = useNavigate();

  useHandlePressKey(KeyCode.ENTER, () => navigate(-1));

  const Footer = () => (
    <>
      <Button
        variant="primary"
        onClick={() => {
          navigate(-1);
        }}
        label={t('COMMON:buttons.understandably') || ''}
      />
    </>
  );

  return (
    <BaseLayout footer={<Footer />}>
      <Container>
        {risks
          ?.filter(
            (item) =>
              (risksPage === 'primary' && item.binding === true) ||
              (risksPage === 'secondary' && item.binding === false)
          )
          .map((item) => (
            <>
              <HeaderLarge3>{item.name}</HeaderLarge3>
              <TextSub3>{item.extendedDescription}</TextSub3>
            </>
          ))}
      </Container>
    </BaseLayout>
  );
};
