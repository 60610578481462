/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TokenInput, TokenInputOption } from '@pulse-web-ui/token-input';

import { PageSubTitle, PageTitle } from '@src/common-components/headers';
import { Container, Skeleton } from '@src/components';
import { GlobalErrorInfo } from '@src/features';
import { useHandlePressKey, useNextStep, useRequest } from '@src/hooks';
import { SportNSActionTypes, Store, WizardActionTypes } from '@src/store';
import { KeyCode } from '@src/types';

import { useSportChoosingFormSchema, useSportDraft } from './hooks';
import { getSportOptions } from './utils';

interface SportChoosingForm {
  sportKinds?: TokenInputOption[];
}

export const FormInsuranceSportNSChoose = () => {
  const {
    state: {
      stateFormNSSport: { sportKinds, choosedSportKinds },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const [formData, setFormData] = useState<SportChoosingForm | undefined>({
    sportKinds: choosedSportKinds,
  });

  const { isLoading, error, res, refetch } = useRequest(
    'sportKindsRequest',
    'get',
    '/v3/references/sport/kinds'
  );

  const tokenInputOptions = useMemo(
    () => getSportOptions(sportKinds?.categories || []),
    [sportKinds]
  );

  const sportChoosingFormSchema = useSportChoosingFormSchema();

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
  } = useForm<SportChoosingForm>({
    resolver: yupResolver(sportChoosingFormSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: formData,
  });

  const handleKeyPressEnter = () => {
    submitPage();
    const values = getValues();
    if (values.sportKinds && values.sportKinds.length > 0) {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  useEffect(() => {
    const subscription = watch((values) => {
      setFormData(values as SportChoosingForm);
      dispatch({
        type: SportNSActionTypes.SetChoosedSportKinds,
        payload: values.sportKinds
          ? (values as SportChoosingForm).sportKinds
          : undefined,
      });

      return;
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const submitPage = handleSubmit((data) => {
    setFormData(data);
    dispatch({
      type: SportNSActionTypes.SetChoosedSportKinds,
      payload: data.sportKinds,
    });
  });

  const validatePage = () =>
    submitPage().then(
      () => !!getValues('sportKinds')?.length && !Object.keys(errors).length
    );

  useNextStep(validatePage);
  useSportDraft();

  useEffect(() => {
    if (!isLoading && res) {
      dispatch({
        type: SportNSActionTypes.SetSportKinds,
        payload: res,
      });
    }
  }, [isLoading, res]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !!error || isLoading,
    });
  }, [error, isLoading]);

  if (isLoading) return <Skeleton />;

  if (!!error) {
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
  }
  return (
    <Container>
      <PageTitle>{t('SPORT_FORM:headers.kindsOfSports')}</PageTitle>
      <PageSubTitle>
        {t('SPORT_FORM:hints.chooseUpToMaxSports', {
          maxCount: sportKinds?.maxCount,
        })}
        <br />
        {t('SPORT_FORM:hints.calculatePrice')}
      </PageSubTitle>
      <Controller
        control={control}
        name="sportKinds"
        render={({ field: { onChange, value }, fieldState }) => (
          <TokenInput
            isStaticOptions
            label={t('SPORT_FORM:labels.enterSportName') || ''}
            value={value}
            options={tokenInputOptions}
            onChange={onChange}
            error={!!fieldState.error?.message}
            {...(fieldState.error?.message
              ? {
                  hintObject: {
                    showIcon: true,
                    message: fieldState.error?.message,
                  },
                }
              : {})}
          />
        )}
      />
    </Container>
  );
};
