import { mixins, styled } from '@pulse-web-ui/theme';

export const PageContent = styled.div`
  padding-top: 0;
`;

export const SectionContainer = styled.div<{ marginBottom?: number }>`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '32px'};
`;

export const PromoWrapper = styled.div`
  ${mixins.flex({ gap: 8 })};
  width: 100%;
`;

export const PromoTextInputWrapper = styled.div`
  flex: auto;
`;
