/* eslint-disable indent */
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Button } from '@pulse-web-ui/button';

import {
  Container,
  FormLabel,
  FormSub,
  FormSubLabel,
  GridColumnContainer,
} from '@src/components';
import {
  sendAnalyticEvent,
  sendUserIdToAnalytics,
} from '@src/components/web-analytic/utils';
import { Product, analyticEvents } from '@src/constants';
import { useIsDesktop } from '@src/hooks';

import { BaseLayout } from '../../layouts/base-layout';

export const BestToPayErrorPage: FC = () => {
  const { t } = useTranslation();

  const currentSelectedProduct = localStorage.getItem('selectedProduct');
  const preset = localStorage.getItem('preset');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId) {
      sendUserIdToAnalytics(userId);
    }
  }, [userId]);

  const retryHandler = () => {
    localStorage.setItem('retryPayment', uuid());
    localStorage.removeItem('paymentDecline');

    switch (currentSelectedProduct) {
      case Product.PETS:
        const event = !!preset
          ? analyticEvents.petToStepPayPreset
          : analyticEvents.petToStepPay;
        sendAnalyticEvent(event);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    switch (currentSelectedProduct) {
      case Product.APARTMENT:
        sendAnalyticEvent(analyticEvents.iflPaymentDecline);
        sendAnalyticEvent(analyticEvents.iflFlatPaymentDecline);
        break;
      case Product.HOUSE:
        if (!!preset) {
          sendAnalyticEvent(analyticEvents.iflHousePresetPaymentDecline);
        } else {
          sendAnalyticEvent(analyticEvents.iflPaymentDecline);
          sendAnalyticEvent(analyticEvents.iflHousePaymentDecline);
        }
        break;
      case Product.PETS:
        const event = !!preset
          ? analyticEvents.petPaymentDeclinePreset
          : analyticEvents.petPaymentDecline;
        sendAnalyticEvent(event);
        break;
      case Product.SPORT:
        sendAnalyticEvent(analyticEvents.sportPaymentDecline);
        break;
      case Product.MITE:
        sendAnalyticEvent(analyticEvents.mitePaymentDecline);
        break;
      case Product.NS:
        sendAnalyticEvent(analyticEvents.nsPaymentDecline);
        break;
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('paymentDecline', '1');
  }, []);

  const isDesktop = useIsDesktop();

  return (
    <BaseLayout verticalAlignment={isDesktop && true}>
      <GridColumnContainer gridStep={24}>
        <Container margin={'40px 0px 0px 0px'}>
          <FormLabel>{t('BEST_TO_PAY:headers.paymentDeclined')}</FormLabel>
          <FormSubLabel>
            {t('BEST_TO_PAY:hints.technicalReasonsPaymentDeclined')}
          </FormSubLabel>
          <FormSub>
            <Button
              onClick={retryHandler}
              variant="primary"
              label={t('COMMON:buttons.tryAgain') || ''}
            />
          </FormSub>
        </Container>
      </GridColumnContainer>
    </BaseLayout>
  );
};
