import { useContext, useEffect } from 'react';

import { Store } from '@src/store';
import { setDraftLS } from '@src/utils';

export const useIflFlatDraft = () => {
  const {
    state: {
      stateFormIFLFlat: {
        selectedIProduct,
        selectedRegion,
        regions,
        risks,
        insuranceSubproducts,
        insuranceSum,
        getPrices,
        selectedDuration,
        selectedDate,
        dadaValue,
        promoCode,
        risksPage,
        fieldWithEmptyDependencies,
        emptyDependencies,
        isObjectDataError,
        newSubobjects,
        isSelectedCustomStreet,
        isSelectedCustomHouse,
        isEmptyStreetSuggestions,
        isEmptyHouseSuggestions,
      },
      stateFormIFLFlat,
      stateUser: { profile, selectedProduct },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    const data = {
      dataObject: stateFormIFLFlat,
      profile,
      selectedProduct,
      currentStep: currentStep || 0,
    };

    setDraftLS(data);
  }, [
    selectedIProduct,
    selectedRegion,
    regions,
    risks,
    insuranceSubproducts,
    insuranceSum,
    getPrices,
    selectedDuration,
    selectedDate,
    dadaValue,
    promoCode,
    risksPage,
    fieldWithEmptyDependencies,
    emptyDependencies,
    isObjectDataError,
    newSubobjects,
    isSelectedCustomStreet,
    isSelectedCustomHouse,
    isEmptyStreetSuggestions,
    isEmptyHouseSuggestions,
  ]);

  return;
};
