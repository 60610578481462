import { VFC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FeatureFlags } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';
import type { Price } from '@src/types';

type Props = {
  prices: Price[];
};

export const PromoCodeDiscount: VFC<Props> = ({ prices }) => {
  const { t } = useTranslation();
  const {
    res: [isFeatureDiscount],
  } = useFeatureFlags([FeatureFlags.PetsDiscount]);

  const discountPrice = prices.find((price) => price?.premiumAndDeltaPromo);

  const promoDiscount = useMemo(
    () =>
      Math.round(
        (1 -
          Number(discountPrice?.premiumAndDeltaPromo) /
            Number(discountPrice?.premiumAndDelta)) *
          100
      ),
    [prices]
  );

  if (!discountPrice) return null;

  return (
    <Trans
      values={{
        promoDiscount,
      }}
    >
      {isFeatureDiscount
        ? t('PETS_FORM_DATA:labels.discountForFirstPeriod')
        : t('COMMON:hints.discountForFirstPeriod')}
    </Trans>
  );
};
