import { styled, typography } from '@pulse-web-ui/theme';

type HeaderProps = {
  align?: 'left' | 'center' | 'right';
  isVip?: boolean;
};

export const PageHeader = styled.h1`
  font-size: 24px;
  font-weight: normal;
`;

export const Header2 = styled.h2`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const Header3 = styled.h3`
  ${typography.bold18}
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Header4 = styled.h4`
  ${typography.regular18}
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const HeaderAdaptive1 = styled(PageHeader)<HeaderProps>`
  ${(props) => (props.align ? `text-align: ${props.align};` : '')}
  color: ${({ theme }) => theme.colors.text.primary};
  ${typography.medium24}
`;

export const HeaderAdaptive1WithSubTitle = styled(PageHeader)<HeaderProps>`
  ${(props) => (props.align ? `text-align: ${props.align};` : '')}
  color: ${({ theme }) => theme.colors.text.primary};
  ${typography.medium24}
  margin-bottom: 8px;
  letter-spacing: 0;
`;

export const HeaderAdaptive3 = styled(Header3)`
  ${typography.pulsSubhead3.bold}
`;

export const HeaderLarge3 = styled(Header3)`
  ${typography.bold20}
  margin-bottom: 8px;
`;

export const HeaderAdaptive4 = styled(Header3)`
  ${typography.pulsHead2.regular}
`;
export const HeaderSubMedium18 = styled.h3`
  ${typography.medium18}
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const HeaderAdaptive5 = styled(Header4)`
  ${typography.regular16};
  color: ${({ theme }) => theme.colors.text.secondary};
  letter-spacing: 0;
  line-height: 24px;
`;

export const HeaderSub = styled.span`
  font-size: 16px;
  margin-left: 8px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text.secondary};
`;
