import { media, styled } from '@pulse-web-ui/theme';

export const StyledAppContent = styled.div`
  padding: 0 16px;
  box-sizing: border-box;

  ${media.desktop} {
    padding: 0;
  }
`;
