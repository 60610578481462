import { TokenInputOption } from '@pulse-web-ui/token-input';

import { InsurePerson, Risk, SelectedDuration } from '@src/types';

export enum SportNSActionTypes {
  SetPromoCode = 'SPORT_NS/SET_PROMO_CODE',
  SetPromoCodeApplyed = 'SPORT_NS/SET_PROMO_CODE_APPLYED',
  SetSportKinds = 'SPORT_NS/SET_SPORT_KINDS',
  SetChoosedSportKinds = 'SPORT_NS/SET_CHOOSED_SPORT_KINDS',
  SetNumberInsurePersons = 'SPORT_NS/SET_NUMBER_INSURE_PERSONS',
  SetInsuranceProduct = 'SPORT_NS/SET_INSURANCE_PRODUCT',
  SetInsuranceProductDate = 'SPORT_NS/SET_INSURANCE_PRODUCT_DATE',
  SetInsuranceProductStartDate = 'SPORT_NS/SET_INSURANCE_PRODUCT_START_DATE',
  SetInsuranceProductEndDate = 'SPORT_NS/SET_INSURANCE_PRODUCT_END_DATE',
  SetRisks = 'SPORT_NS/SET_RISKS',
  SetSublimit = 'SPORT_NS/SET_SUBLIMIT',
  SetInsuranceSum = 'SPORT_NS/SET_INSURANCE_SUM',
  SetInsurancePrice = 'SPORT_NS/SET_INSURANCE_PRICE',
  SetInsurePersons = 'SPORT_NS/SET_INSURE_PERSONS',
  SetSelectedDuration = 'SPORT_NS/SET_SELECTED_DURATION',
}

export type InsuranceProductType = {
  code: string;
  maxObjectsNumber: number;
  maxProductLimit: string;
  minProductLimit: string;
  name: string;
  step: string;
};

export type InsuranceProductDateType = {
  startDate: string;
  endDate: string;
  maxDuration: number;
  minDuration: number;
};

export type SelectedDate = Date;

export interface SportSublimit {
  totalMinLimit: string;
  totalMaxLimit: string;
  totalDefaultInsuranceSum?: string;
  totalStep?: string;
}
export interface InsurancePrice {
  isSuccessfulPromo: boolean;
  premiumAndDelta: string;
  premiumAndDeltaPromo?: string;
}

export interface SportNSStoreState {
  insuranceProduct?: InsuranceProductType;
  insuranceProductDate?: InsuranceProductDateType;
  selectedStartDate: null | SelectedDate;
  selectedEndDate: null | SelectedDate;
  numberInsurePersons: number;
  promoCode?: string;
  promoCodeApplyed?: string;
  sportKinds?: SportKinds;
  choosedSportKinds?: TokenInputOption[];
  risks?: Risk[];
  sublimit?: SportSublimit;
  insuranceSum?: number;
  insurancePrice?: InsurancePrice;
  insurePersons: InsurePerson[];
  selectedDuration: null | SelectedDuration | string;
}
interface Sport {
  code: string;
  name: string;
}
export interface Category {
  displayCategory: string;
  sports: Sport[];
}
export interface SportKinds {
  categories: Category[];
  minCount: number;
  maxCount: number;
}
