import { insurancePersonDefaultData } from '@src/constants';
import {
  InsurancePrice,
  InsuranceProductPolicyType,
  InsurePerson,
} from '@src/types';

export interface MiteStoreState {
  promoCode?: string;
  choosedPolicy?: string;
  insurancePrice?: InsurancePrice;
  insuranceProductPolicy?: InsuranceProductPolicyType;
  insuredPersons: InsurePerson[];
  numberInsuredPersons: number;
}

export const initMiteStoreState: MiteStoreState = {
  insuredPersons: [insurancePersonDefaultData],
  numberInsuredPersons: 1,
};
