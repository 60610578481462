import { useContext, useEffect } from 'react';

import { Store } from '@src/store';
import { setDraftLS } from '@src/utils';

export const usePetsDraft = () => {
  const {
    state: {
      stateFormPets: {
        selectedIProduct,
        risks,
        insuranceSum,
        getPrices,
        getPricesFull,
        selectedDuration,
        selectedDate,
        promoCode,
        promoCodeApplyed,
        risksPage,
        pets,
        selectedPetData,
        sublimits,
        selectedPetKindName,
        currentSum,
        presetData,
      },
      stateFormPets,
      stateUser: { profile, selectedProduct },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    if (!presetData) {
      const data = {
        dataObject: stateFormPets,
        profile,
        selectedProduct,
        currentStep: currentStep || 0,
      };

      setDraftLS(data);
    }
  }, [
    selectedIProduct,
    risks,
    insuranceSum,
    getPrices,
    getPricesFull,
    selectedDuration,
    selectedDate,
    promoCode,
    promoCodeApplyed,
    risksPage,
    pets,
    selectedPetData,
    sublimits,
    selectedPetKindName,
    currentSum,
    presetData,
  ]);

  return;
};
