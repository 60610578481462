import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ThemeContainerIn } from './theme-container-in';

export const ThemeContainer: FC = ({ children }) => {
  const [searchParams] = useSearchParams();
  const [themeUrl, setThemeUrl] = useState<string | undefined>();

  useEffect(() => {
    setThemeUrl(searchParams.get('personalization') || undefined);
  }, []);

  return <ThemeContainerIn themeUrl={themeUrl}>{children}</ThemeContainerIn>;
};
