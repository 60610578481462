export const useScrollToErrorElement = (errors: any) => {
  let index = null;
  const keys = Object.keys(errors);
  for (let i = 0; i <= keys.length; i++) {
    const key = keys[i];
    const fields = errors[key];

    if (Array.isArray(fields)) {
      index = fields.findIndex((item) => item);
      break;
    }
  }
  const element = document.querySelector(`[data-fieldindex="${index}"]`);
  element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
};
