import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const headers = {
  'X-Process-ID': uuidv4(),
  'Content-type': 'application/json',
};

export const refreshToken = () => {
  let authHeaders = {
    ...headers,
    Authorization: '',
  };

  const refToken = sessionStorage.getItem('refresh_token');

  if (refToken) {
    authHeaders = {
      ...headers,
      Authorization: `Bearer ${refToken}`,
    };
    return Axios.post(
      `${window.envUrls.BASEURL}/v1/user/refresh-token`,
      {},
      {
        headers: authHeaders,
      }
    );
  }

  return;
};
