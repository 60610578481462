/* eslint-disable @typescript-eslint/no-non-null-assertion */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { Map, Placemark, ZoomControl, useYMaps } from '@pbe/react-yandex-maps';
import type { FC } from 'react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Alert } from '@pulse-web-ui/alert';
import type { Option } from '@pulse-web-ui/autocomplete-select';
import { Button } from '@pulse-web-ui/button';
import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';
import { Info } from '@pulse-web-ui/icons';
import { useTheme } from '@pulse-web-ui/theme';

import {
  AdaptiveContainer,
  ButtonWrapper,
  Container,
  FormLabel,
  FormSub,
  MapContainer,
  SiteAgreements,
  Skeleton,
  SmartAddress,
} from '@src/components';
import { MapWrapper } from '@src/components/form-elements';
import {
  SmartAddressValues,
  getFormDefaultValues,
  getSmartAddressSchema,
} from '@src/components/form-elements/dadata/smart-address';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  DEFAULT_FIAS_ID,
  Product,
  USER_AGREEMENTS_URL,
  UseQueryStatus,
  analyticEvents,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import {
  useBlockNextStep,
  useHandlePressKey,
  useNextStep,
  useRequest,
  useValidateProfileAuth,
} from '@src/hooks';
import {
  AuthActionTypes,
  IFLFlatActionTypes,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { DraftType, KeyCode, OrderRequestData, Region } from '@src/types';
import { suggestionsResConverter } from '@src/utils';

import { useIflFlatDraft } from './hooks';

const yaMapOptions = { suppressMapOpenBlock: true };

const modules = [
  'control.ZoomControl',
  'control.FullscreenControl',
  'borders',
  'util.bounds',
];

const modulesConfig = ['geoObject.addon.balloon', 'geoObject.addon.hint'];
type Lat = number;
type Lon = number;

type Center = [Lat, Lon];

type MapCenter = {
  center: Center;
  zoom: number;
};

const initialMapCenter: MapCenter = {
  center: [55.75, 37.57],
  zoom: 11,
};

export const FormInsuranceAddress: FC = () => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const {
    state: {
      stateFormIFLFlat: {
        selectedIProduct,
        dadaValue,
        selectedRegion,
        regions,
        isSelectedCustomHouse,
        isSelectedCustomStreet,
        isEmptyStreetSuggestions,
        isEmptyHouseSuggestions,
      },
      stateDraft,
      stateUser: { cachedDadaValue, profile },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  const smartAddressSchema = useMemo(
    () =>
      getSmartAddressSchema(dadaValue).shape({
        flat: yup.string().required('INSURANCE_FORMS:errors.specifyApartment'),
      }),
    [dadaValue]
  );

  const methods = useForm<SmartAddressValues>({
    resolver: yupResolver(smartAddressSchema),
    shouldFocusError: true,
    mode: 'onBlur',
    defaultValues: getFormDefaultValues(
      selectedRegion?.region || '',
      dadaValue
    ),
  });

  const ymaps = useYMaps(modules);
  const ContainerRef = useRef<null | HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [yaMapCoords, setYaMapCoords] = useState<[Lat, Lon] | []>([]);
  const [latLon, setLatLon] = useState<Center | []>([]);
  const [mapCenter, setMapCenter] = useState<MapCenter>(initialMapCenter);
  const [regionCenter, setRegionCenter] = useState<[Lat, Lon]>([0, 0]);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [isHouseNotFound, setIsHouseNotFound] = useState(false);
  const [mapMessage, setMapMessage] = useState('');
  const [draftFlat, setDraftFlat] = useState(dadaValue?.data.flat || '');

  const { profileRefetch, profileIsLoading, profileError } =
    useValidateProfileAuth();

  const toggleShowMap = () => {
    setShowMap((prevState) => !prevState);
  };

  const buttonLable = showMap
    ? t('COMMON:labels.hideMap')
    : t('COMMON:labels.selectOnMap');
  const [dadataStringInit, setDadataStringInit] = useState<string>();
  const [regionStringInit, setRegionStringInit] = useState(true);
  const draft = stateDraft.draft as unknown as DraftType<OrderRequestData>;

  useEffect(() => {
    if (!profile?.profile.lastName) {
      profileRefetch();
    }
  }, []);

  useLayoutEffect(() => {
    if (
      draft?.order?.personProperties?.address.address &&
      !dadataStringInit &&
      !dadaValue
    ) {
      setDadataStringInit(draft?.order?.personProperties?.address.address);
    }
  }, [dadataStringInit, dadaValue]);

  const handleViewAgreement = () => {
    sendAnalyticEvent(analyticEvents.userAgreement);
  };

  const setDadaValue = (value: undefined | DaDataAddressSuggestion) => {
    setDadataStringInit('');
    dispatch({
      type: IFLFlatActionTypes.SetDadaValue,
      payload: value,
    });
    sendAnalyticEvent(analyticEvents.addressSelectedRealty);
  };

  const {
    error,
    res: dadataRes,
    isLoading,
    status,
    isFetchedAfterMount,
    refetch: geolocateRefetch,
  } = useRequest(
    'getFlatYaCoords',
    'post',
    '/v3/geolocate/address',
    {
      lat:
        (yaMapCoords?.length > 1 && Number(yaMapCoords[0])) ||
        dadaValue?.data.geo_lat ||
        0,
      lon:
        (yaMapCoords?.length > 1 && Number(yaMapCoords[1])) ||
        dadaValue?.data.geo_lon ||
        0,
    },
    [yaMapCoords],
    true,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: scoringIsLoading,
    error: scoringError,
    refetch: scoringRefetch,
    status: scoringStatus,
  } = useRequest(
    'scoringRequest',
    'post',
    '/v1/scoring',
    {
      productCode: selectedIProduct?.code,
      address: {
        address: dadaValue?.value || '',
        addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
      },
    },
    [cachedDadaValue?.value],
    true,
    authTokens?.authorization?.accessToken
  );

  const handleKeyPressEnter = () => {
    validatePage().then((isValidResponse) => {
      if (isValidResponse) {
        dispatch({
          type: WizardActionTypes.UpdateWantNextStep,
          payload: true,
        });
      }
    });
  };

  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [
    selectedIProduct,
    dadaValue,
    selectedRegion,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMapClick = (e: any) => {
    setDraftFlat('');
    setIsHouseNotFound(false);
    setLatLon([]);
    setMapCenter({
      center: e.get('coords'),
      zoom: 11,
    });
    setYaMapCoords(e.get('coords'));
    geolocateRefetch();
  };

  const yaMapLoadGetPosition = useCallback(
    (ymaps: any) => {
      ymaps.borders
        .load('RU', {
          lang: 'ru',
          quality: 3,
        })
        .then((res: any) => {
          const filteredRegion = res.features.filter(
            (region: any) =>
              region.properties.name ===
              selectedRegion?.regionName.replace(/город /, '')
          );

          if (filteredRegion[0]) {
            const newMapState = ymaps.util.bounds.getCenterAndZoom(
              ymaps.util.bounds.fromPoints([
                ...filteredRegion[0].geometry.coordinates.flat(),
              ]),
              [containerWidth, 320]
            );

            if (!(dadaValue?.data.geo_lat && dadaValue?.data.geo_lon)) {
              setRegionCenter(newMapState.center);
              setMapCenter(newMapState);
            }
          }
        });
    },
    [selectedRegion, dadaValue, containerWidth]
  );

  const storeSelectedRegion = useCallback((data: Region | undefined) => {
    dispatch({
      type: IFLFlatActionTypes.SetSelectedRegion,
      payload: data,
    });
  }, []);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const handleRegionChange = useCallback(
    (val?: string) => {
      setStepUpdated();
      if (val) {
        selectedIProduct?.regions.map((item) => {
          if (item.region === val) {
            storeSelectedRegion(item);
            sendAnalyticEvent(analyticEvents.regionSelectedRealty);
          }
        });
      } else {
        storeSelectedRegion(undefined);
      }
      dispatch({
        type: IFLFlatActionTypes.SetInsuranceSubproducts,
        payload: undefined,
      });
    },
    [selectedIProduct, selectedRegion]
  );

  const submitPage = methods.handleSubmit((data) => {
    dispatch({
      type: IFLFlatActionTypes.SetDadaValue,
      payload: dadaValue && {
        ...dadaValue,
        value: `${data.city}${data.street && `, ${data.street}`}, ${
          data.house
        }, кв ${data.flat}`,
      },
    });
  });

  const getFormValidateStatus = useCallback(() => {
    let isValid;
    const formValues = methods.getValues();

    if (!formValues.street) {
      delete formValues.street;
    }

    isValid =
      !Object.values(formValues).some((val) => !val) &&
      !Object.keys(methods.formState.errors).length;

    if (formValues.street) {
      if (
        !dadaValue?.data.street_fias_id &&
        isEmptyStreetSuggestions &&
        !isSelectedCustomStreet
      ) {
        isValid = false;
      }
    }

    if (
      !dadaValue?.data.house_fias_id &&
      isEmptyHouseSuggestions &&
      !isSelectedCustomHouse
    ) {
      isValid = false;
    }

    return isValid;
  }, [
    isEmptyHouseSuggestions,
    isEmptyStreetSuggestions,
    isSelectedCustomHouse,
    isSelectedCustomStreet,
    dadaValue,
  ]);

  const validatePage = () =>
    submitPage().then(() => {
      const isValid = getFormValidateStatus();

      if (cachedDadaValue?.value !== dadaValue?.value && isValid) {
        scoringRefetch();

        return false;
      }

      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: !isValid,
      });

      return isValid;
    });

  useNextStep(validatePage);
  useIflFlatDraft();

  useEffect(() => {
    // Восстановление состояния шага формы на основе данных из черновика
    if (
      draft?.order?.personProperties?.address?.regionCode &&
      regionStringInit &&
      selectedIProduct &&
      selectedIProduct?.regions.length !== 0
    ) {
      handleRegionChange(draft!.order!.personProperties!.address!.regionCode!);
      setRegionStringInit(false);
    }
  }, [draft, regionStringInit, selectedIProduct]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setContainerWidth(ContainerRef.current?.clientWidth || 0);
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    sendAnalyticEvent(analyticEvents.startRealty);
    sendAnalyticEvent(analyticEvents.iflStep1Address);
    sendAnalyticEvent(analyticEvents.iflFlatStep1Address);
  }, []);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: '/apartment',
    });
    setContainerWidth(ContainerRef.current?.clientWidth || 0);
  }, []);

  useLayoutEffect(() => {
    if (!isLoading && isFetchedAfterMount) {
      if (
        status === UseQueryStatus.SUCCESS &&
        (!dadataRes || dadataRes?.suggestions.length === 0)
      ) {
        setMapMessage(t('INSURANCE_FORMS:errors.couldNotLocate') || '');
      }

      if (dadataRes) {
        const dadataSuggestionsArray =
          suggestionsResConverter(dadataRes).suggestions;
        const addressByRegion: DaDataAddressSuggestion | undefined =
          dadataSuggestionsArray.find((item: DaDataAddressSuggestion) => {
            if (regions) {
              return regions.find(
                (elem) => elem.value === item.data.region_fias_id
              );
            }
          });

        const regionId = addressByRegion?.data?.region_fias_id;
        const regionFiasIdArray = dadataSuggestionsArray.map(
          (item: DaDataAddressSuggestion) => item.data.region_fias_id
        );
        const unique = [...new Set(regionFiasIdArray)];
        if (unique.length > 0 && regionId) {
          setDadaValue({
            ...addressByRegion,
            data: { ...addressByRegion.data, flat: draftFlat },
          });
          handleRegionChange(regionId);
          setIsHouseNotFound(false);
          setMapMessage('');
        } else if (!dadaValue) {
          setYaMapCoords([]);
          storeSelectedRegion(undefined);
          setDadaValue(undefined);
        }

        if (!addressByRegion && dadataRes?.suggestions.length !== 0) {
          setMapMessage(t('INSURANCE_FORMS:errors.cannotInsureInRegion') || '');
        }
      }
    }
  }, [isLoading, dadataRes, status, regions, isFetchedAfterMount]);

  useLayoutEffect(() => {
    if (dadaValue) {
      let newLatLon: Center = regionCenter;

      if (dadaValue.data.geo_lat && dadaValue.data.geo_lon) {
        newLatLon = [
          Number(dadaValue.data.geo_lat),
          Number(dadaValue.data.geo_lon),
        ];
        setMapCenter({
          center: newLatLon,
          zoom: 16,
        });
        setMapMessage('');
      }
      setLatLon(newLatLon);
      return;
    }
    if (yaMapCoords.length) {
      setLatLon(yaMapCoords);
    }
  }, [dadaValue, yaMapCoords]);

  useLayoutEffect(() => {
    if (selectedIProduct) {
      const filteredRegions: Option[] = [];

      selectedIProduct?.regions.forEach((el) =>
        filteredRegions.push({
          label: el.regionName,
          value: el.region,
        })
      );

      dispatch({
        type: IFLFlatActionTypes.SetRegions,
        payload: filteredRegions,
      });
    }
  }, [selectedIProduct]);

  useEffect(() => {
    if (
      selectedRegion &&
      ymaps &&
      !(dadaValue?.data.geo_lat && dadaValue?.data.geo_lon)
    ) {
      yaMapLoadGetPosition(ymaps);
    }
  }, [selectedRegion, ymaps, dadaValue]);

  useLayoutEffect(() => {
    if (!scoringIsLoading && scoringStatus === UseQueryStatus.SUCCESS) {
      dispatch({
        type: UserActionTypes.SetCachedDadaValue,
        payload: dadaValue,
      });

      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  }, [scoringStatus, scoringIsLoading]);

  useEffect(() => {
    if (scoringError) {
      dispatch({
        type: UserActionTypes.SetCachedDadaValue,
        payload: undefined,
      });

      dispatch({
        type: WizardActionTypes.SetUpdateFormState,
        payload: false,
      });

      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: false,
      });
    }
  }, [scoringError]);

  useEffect(() => {
    if (error) {
      setMapMessage(t('INSURANCE_FORMS:errors.couldNotLocate') || '');
    }
  }, [error]);

  useBlockNextStep(
    profileIsLoading,
    profileError ||
      !selectedIProduct ||
      (!!dadaValue?.data.flat && !getFormValidateStatus())
  );

  if (profileError) {
    return (
      <GlobalErrorInfo
        pending={profileIsLoading}
        retrayHandler={profileRefetch}
      />
    );
  }

  if (scoringIsLoading || profileIsLoading) return <Skeleton />;

  return (
    <Container>
      <FormLabel>{t('COMMON:headers.enterAddress')}</FormLabel>
      <FormSub>{t('IFL_FLAT_FORM:hints.weTrustOurClients')}</FormSub>
      <FormProvider {...methods}>
        <SmartAddress
          type={Product.APARTMENT}
          handleRegionChange={handleRegionChange}
          selectedRegion={selectedRegion}
          regions={regions}
          dadaValue={dadaValue}
          setHouseSelectedStatus={setIsHouseNotFound}
          isSelectedCustomStreet={isSelectedCustomStreet}
          isSelectedCustomHouse={isSelectedCustomHouse}
          isEmptyStreetSuggestions={isEmptyStreetSuggestions}
          isEmptyHouseSuggestions={isEmptyHouseSuggestions}
        />
      </FormProvider>
      <AdaptiveContainer ref={ContainerRef}>
        {showMap && (
          <MapWrapper>
            <MapContainer>
              <Map
                onLoad={yaMapLoadGetPosition}
                width={containerWidth}
                height="320px"
                onClick={onMapClick}
                options={yaMapOptions}
                state={mapCenter}
                modules={modules}
              >
                <Placemark
                  modules={modulesConfig}
                  key={'index'}
                  geometry={(latLon.length > 0 && latLon) || [0, 0]}
                />
                <ZoomControl
                  options={{
                    position: {
                      top: 15,
                      right: 15,
                    },
                  }}
                />
              </Map>
            </MapContainer>
            {mapMessage && !isHouseNotFound && (
              <Alert type="warning" description={mapMessage} />
            )}
            {isHouseNotFound && (
              <Alert
                type="warning"
                title={t('INSURANCE_FORMS:errors.noHouseOnMap') || ''}
                description={
                  t('INSURANCE_FORMS:errors.enteredCorrectAddressContinue') ||
                  ''
                }
                icon={<Info width={24} color={theme.colors.icon.primary} />}
              />
            )}
          </MapWrapper>
        )}
        <ButtonWrapper>
          <Button variant="text" onClick={toggleShowMap} label={buttonLable} />
        </ButtonWrapper>
        <SiteAgreements order="0">
          {t('COMMON:hints.acceptTerms')}{' '}
          <a
            href={USER_AGREEMENTS_URL}
            target="_blank"
            onClick={handleViewAgreement}
            download
          >
            {t('COMMON:buttons.userAgreement')}
          </a>
        </SiteAgreements>
      </AdaptiveContainer>
    </Container>
  );
};
