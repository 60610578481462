import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { StepWizardForm } from '@src/features';
import { useResetSportNSSteps } from '@src/hooks/use-reset-sport-steps';
import { AuthActionTypes, Store, UserActionTypes } from '@src/store';

import { FormInsuranceSportNSChoose } from './form-insurance-sport-ns-choose';
import { FormInsuranceSportNSCoverage } from './form-insurance-sport-ns-coverage';
import { FormInsuranceSportNSData } from './form-insurance-sport-ns-data';
import { FormSportNSQuantity } from './form-sport-ns-quantity';
import { useAnalyticsSport } from './hooks';

export const SportNSForm = () => {
  const {
    state: {
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);

  useAnalyticsSport();

  const navigate = useNavigate();

  const goBack = () => {
    if (currentStep === 1) {
      dispatch({
        type: AuthActionTypes.SetConfirmationId,
        payload: undefined,
      });

      dispatch({
        type: AuthActionTypes.SetAuthenticationToken,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedPhoneNumber,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetAuthenticationRefetchUid,
        payload: uuidv4(),
      });

      const productDirectLink = localStorage.getItem('productDirectLink');
      if (productDirectLink && productDirectLink !== null) {
        navigate('/');
      } else {
        navigate('/subscription-choice');
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <StepWizardForm
      maxStep={4}
      useResetSteps={useResetSportNSSteps}
      goBack={goBack}
    >
      {currentStep === 1 && <FormSportNSQuantity />}
      {currentStep === 2 && <FormInsuranceSportNSChoose />}
      {currentStep === 3 && <FormInsuranceSportNSCoverage />}
      {currentStep === 4 && <FormInsuranceSportNSData />}
    </StepWizardForm>
  );
};
