/* eslint-disable indent */
import { useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { isBrowser } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import {
  ConsultationsIcon,
  DocumentsIcon,
  RegulatorIcon,
} from '@pulse-web-ui/colored-icons';
import { DisplayQRButton } from '@pulse-web-ui/display-qr-button';
import { useTheme } from '@pulse-web-ui/theme';

import {
  PageSubTitleRegular18,
  PageTitle,
  StyledFormSub,
} from '@src/common-components/headers';
import {
  GridColumnContainer,
  HeaderSubMedium18,
  UniversalColumn,
  UniversalColumnsWrapper,
} from '@src/components';
import {
  sendAnalyticEvent,
  sendUserIdToAnalytics,
} from '@src/components/web-analytic/utils';
import {
  Product,
  analyticEvents,
  nonSubscriptionProducts,
} from '@src/constants';
import { clearLocalStorageOnSuccessPayment, cleartDraft } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';

export const BestToPaySuccessPage: FC = () => {
  const theme: any = useTheme();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const storedEmail = localStorage.getItem('email');
  const selectedDate = localStorage.getItem('selectedDate') as string;
  const currentSelectedProduct = localStorage.getItem('selectedProduct');
  const preset = localStorage.getItem('preset');
  const userId = localStorage.getItem('userId');

  const formatSelectedDate = new Date(JSON.parse(selectedDate))
    .toLocaleDateString('ru-RU', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
    .replace('г.', t('COMMON:hints.ofYear'));

  const pageTitle = nonSubscriptionProducts.includes(
    currentSelectedProduct || ''
  )
    ? t('BEST_TO_PAY:headers.policyIssued')
    : t('BEST_TO_PAY:headers.yourPolicyIssued');

  const pageSubTitle = useMemo(() => {
    switch (currentSelectedProduct) {
      case Product.PETS:
        return (
          <span>
            <Trans
              values={{
                date: selectedDate ? formatSelectedDate : '',
                email: storedEmail || '',
              }}
            >
              {t('BEST_TO_PAY:hints.sentToEmailWereInTouch')}
            </Trans>
          </span>
        );
      default:
        return (
          <span>
            <Trans
              values={{
                date: selectedDate ? formatSelectedDate : '',
                email: storedEmail || '',
              }}
            >
              {t('BEST_TO_PAY:hints.sentItToEmail')}
            </Trans>
          </span>
        );
    }
  }, [currentSelectedProduct, t]);

  const appButtonLabel = useMemo(() => {
    switch (currentSelectedProduct) {
      case Product.PETS:
      case Product.MITE:
        return t('COMMON:buttons.installApp');
      default:
        return t('COMMON:buttons.downloadApp');
    }
  }, [currentSelectedProduct, t]);

  let insurancePolicyName = t('BEST_TO_PAY:hints.insurance');

  if (nonSubscriptionProducts.includes(currentSelectedProduct || '')) {
    insurancePolicyName = t('BEST_TO_PAY:hints.policy');
  }

  useEffect(() => {
    if (userId) {
      sendUserIdToAnalytics(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (searchParams.get('reference')) {
      localStorage.setItem(
        'referenceSuccess',
        String(searchParams.get('reference'))
      );
      localStorage.setItem(
        'operationId',
        String(searchParams.get('operation'))
      );
    }

    switch (currentSelectedProduct) {
      case Product.APARTMENT:
        sendAnalyticEvent(analyticEvents.iflPaymentSuccess);
        sendAnalyticEvent(analyticEvents.iflFlatPaymentSuccess);
        break;

      case Product.HOUSE:
        if (!!preset) {
          sendAnalyticEvent(analyticEvents.iflHousePresetPaymentSuccess);
        } else {
          sendAnalyticEvent(analyticEvents.iflPaymentSuccess);
          sendAnalyticEvent(analyticEvents.iflHousePaymentSuccess);
        }
        break;
      case Product.PETS:
        const event = !!preset
          ? analyticEvents.petPaymentSuccessPreset
          : analyticEvents.petPaymentSuccess;
        sendAnalyticEvent(event);
        break;

      case Product.NS:
        sendAnalyticEvent(analyticEvents.paymentSuccessAccident);
        sendAnalyticEvent(analyticEvents.nsPaymentSuccess);
        break;

      case Product.SPORT:
        sendAnalyticEvent(analyticEvents.sportPaymentSuccess);
        break;

      case Product.MITE:
        sendAnalyticEvent(analyticEvents.mitePaymentSuccess);
        break;

      default:
        break;
    }
    clearLocalStorageOnSuccessPayment();
  }, []);

  useEffect(() => {
    cleartDraft();
  }, []);

  const getFirstCharacter = () => {
    if (currentSelectedProduct !== Product.PETS) {
      return t('BEST_TO_PAY:hints.changeAndPause');
    } else {
      return t('BEST_TO_PAY:hints.suspend');
    }
  };

  return (
    <BaseLayout verticalAlignment={true}>
      <GridColumnContainer gridStep={1}>
        <UniversalColumn align="left" gap={0}>
          <PageTitle>{pageTitle}</PageTitle>
          <PageSubTitleRegular18 marginBottom={32}>
            {pageSubTitle}
          </PageSubTitleRegular18>
        </UniversalColumn>
        <StyledFormSub margin="0 0 32px">
          {isBrowser ? (
            <DisplayQRButton buttonLabel={appButtonLabel} />
          ) : (
            <Button
              variant="primary"
              label={appButtonLabel}
              onClick={() =>
                window.open(
                  window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK,
                  '_blank'
                )
              }
            />
          )}
        </StyledFormSub>
      </GridColumnContainer>
      <UniversalColumn align="left">
        <HeaderSubMedium18>
          {t('BEST_TO_PAY:headers.InApplicationYouCan')}
        </HeaderSubMedium18>
      </UniversalColumn>
      <UniversalColumnsWrapper>
        {!nonSubscriptionProducts.includes(currentSelectedProduct || '') && (
          <UniversalColumn
            align="left"
            bgColor={theme.colors?.surface?.background}
            gap={16}
          >
            <RegulatorIcon />
            <div>{`${getFirstCharacter()} ${t(
              'BEST_TO_PAY:hints.orCancel'
            )}`}</div>
          </UniversalColumn>
        )}
        <UniversalColumn
          align="left"
          bgColor={theme.colors?.surface?.background}
          gap={16}
        >
          <ConsultationsIcon />
          <div>{t('BEST_TO_PAY:hints.getAdvice')}</div>
        </UniversalColumn>
        <UniversalColumn
          align="left"
          bgColor={theme.colors?.surface?.background}
          gap={16}
        >
          <DocumentsIcon />
          <div>
            {`${t(
              'BEST_TO_PAY:hints.getUsefulMaterialsAndDocuments'
            )} ${insurancePolicyName}`}
          </div>
        </UniversalColumn>
      </UniversalColumnsWrapper>
    </BaseLayout>
  );
};
