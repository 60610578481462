/* eslint-disable indent */
import type {
  DaDataAddressBounds,
  DaDataAddressSuggestion,
} from '@pulse-web-ui/dadata';

import { SmartAddressBounds } from '../smart-address.types';

export const splitAddress = (
  bound: DaDataAddressBounds,
  dadataValue?: DaDataAddressSuggestion
): DaDataAddressSuggestion | undefined => {
  if (dadataValue) {
    const { data } = dadataValue;
    switch (bound) {
      case SmartAddressBounds.CITY:
        return {
          ...dadataValue,
          value: data.settlement_with_type || data.city || '',
        };
      case SmartAddressBounds.STREET:
        return {
          ...dadataValue,
          value: data.street_with_type || '',
        };
      case SmartAddressBounds.HOUSE:
        return {
          ...dadataValue,
          value: `${data.house_type && data.house_type}${
            data.house && ` ${data.house}`
          }${data.block_type && ` ${data.block_type}`}${
            data.block && ` ${data.block}`
          }`.trimStart(),
        };
      case SmartAddressBounds.FLAT:
        return {
          ...dadataValue,
          value: data.flat || '',
        };
      default:
        return dadataValue;
    }
  }
};
