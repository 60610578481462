import { media, styled } from '@pulse-web-ui/theme';

export const StyledIframe = styled.iframe`
  width: 100%;
  height: calc(100% - 48px);
  position: absolute;
  left: 0;
  top: 48px;

  ${media.desktop} {
    height: 100%;
    top: 0;
  }
`;
