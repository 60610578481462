import { useContext, useEffect } from 'react';

import { Store } from '@src/store';
import { setDraftLS } from '@src/utils';

export const useNsDraft = () => {
  const {
    state: {
      stateFormNS: {
        insuranceProduct,
        ageRatios,
        numberInsurePersons,
        risks,
        selectedMainRisks,
        selectedAdditionalRisks,
        insuranceSum,
        selectedDate,
        selectedDuration,
        getPrices,
        subscriptionDateLimit,
        promoCode,
      },
      stateFormNS,
      stateUser: { profile, selectedProduct },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    const data = {
      dataObject: stateFormNS,
      profile,
      selectedProduct,
      currentStep: currentStep || 0,
    };

    setDraftLS(data);
  }, [
    insuranceProduct,
    ageRatios,
    numberInsurePersons,
    risks,
    selectedMainRisks,
    selectedAdditionalRisks,
    insuranceSum,
    selectedDate,
    selectedDuration,
    getPrices,
    subscriptionDateLimit,
    promoCode,
  ]);

  return;
};
