import { useContext, useEffect } from 'react';

import { OrderActionTypes, Store } from '@src/store';
import { AddressDataType, OrderRequestData } from '@src/types';
import { prepareDate, subObjectConverter } from '@src/utils';

export const useIflOrderHouseData = () => {
  const {
    state: {
      stateFormIFLHouse: {
        selectedIProduct,
        dadaValue,
        insuranceSubproducts,
        selectedDate,
        promoCode,
        insuranceSum,
        selectedDuration,
        risks,
        selectedBuildingMaterialCode,
        getPrices,
      },
    },
    dispatch,
  } = useContext(Store);

  const addressData: AddressDataType = {
    address: dadaValue?.value || '',
    addressCode: dadaValue?.data?.fias_id || undefined,
    regionCode: dadaValue?.data.region_fias_id,
    streetCode: dadaValue?.data.street_fias_id || undefined,
    houseCode: dadaValue?.data.house_fias_id || undefined,
    flatCode: dadaValue?.data.flat_fias_id || undefined,
  };

  if (dadaValue?.data.settlement_fias_id) {
    addressData.placeCode = dadaValue?.data.settlement_fias_id;
  }

  if (dadaValue?.data.city_fias_id) {
    addressData.cityCode = dadaValue?.data.city_fias_id;
  }

  const orderData: OrderRequestData = {
    productCode: selectedIProduct?.code,
    contractDuration: selectedDuration,
    risks: risks
      ?.filter((item) => item.active === true)
      .map((item) => item.code),
    effectiveSince: prepareDate(selectedDate),
    insuranceSum: String(insuranceSum),
    personProperties: {
      subObjects:
        insuranceSubproducts?.subObjects &&
        subObjectConverter(insuranceSubproducts.subObjects),
      address: addressData,
    },
    ...(promoCode &&
      getPrices?.isSuccessfulPromo !== false && { promoCode: promoCode }),
    buildingMaterial: selectedBuildingMaterialCode,
  };

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderRequestData,
      payload: orderData,
    });
  }, [
    selectedIProduct,
    dadaValue,
    insuranceSubproducts,
    selectedDate,
    promoCode,
    insuranceSum,
    selectedDuration,
    risks,
    getPrices?.isSuccessfulPromo,
  ]);
};
