/* eslint-disable indent */
import { useContext, useEffect, useState } from 'react';

import { Product } from '@src/constants';
import { IFLHouseStoreState, Store } from '@src/store';
import type { PetsStoreState } from '@src/store/pets';

const getStoreName = (product: Product | null) => {
  switch (product) {
    case Product.PETS:
      return 'stateFormPets';
    case Product.HOUSE:
      return 'stateFormIFLHouse';
    default:
      return null;
  }
};

export const useCloneStore = (product: Product | null) => {
  const { state } = useContext(Store);
  const [clonedStore, setClonedStore] = useState<
    PetsStoreState | IFLHouseStoreState | undefined
  >();

  useEffect(() => {
    const storeName = getStoreName(product);
    if (storeName) {
      setClonedStore(state[storeName]);
    }
  }, []);

  return clonedStore;
};
