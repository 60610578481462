import type {
  IFLFlatStoreState,
  IFLHouseStoreState,
  NSStoreState,
  PetsStoreState,
  SportNSStoreState,
} from '@src/store';

type DraftType =
  | {
      common: {
        productType: string;
        currentStep: number;
        userId: string;
      };
      state?:
        | IFLFlatStoreState
        | IFLHouseStoreState
        | NSStoreState
        | SportNSStoreState
        | PetsStoreState;
    }
  | undefined;

export const getDraft = () => {
  const selectedProduct = localStorage.getItem('selectedProduct');

  let lsDraft;
  let resData: DraftType;

  if (selectedProduct !== null) {
    lsDraft = localStorage.getItem(selectedProduct);
  }

  if (lsDraft) {
    resData = JSON.parse(lsDraft);
  }

  return { resData };
};
