import {
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { SelectorCard } from '@pulse-web-ui/selector-card';

import {
  AdaptiveListWrapper,
  Container,
  RiskWrapper,
  Skeleton,
} from '@src/components';
import {
  FormHead,
  FormSubhead,
} from '@src/components/form-elements/form-elements';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useHandlePressKey, useNextStep, useRequest } from '@src/hooks';
import { Store } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode, Risk } from '@src/types';

import { useNsDraft } from './hooks';

export const FormInsuranceRisks: FC = memo(() => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormNS: { insuranceProduct, risks, selectedMainRisks },
      stateWizard: { wantNextStep },
    },
    dispatch,
  } = useContext(Store);

  const [isListBoxError, setIsListBoxError] = useState(false);

  const mainRisks = useMemo(
    () => risks?.filter((elem: Risk) => elem.binding),
    [risks]
  );

  const { isLoading, error, res, refetch } = useRequest(
    'formNSGetRisks',
    'post',
    '/v1/references/get-risks',
    {
      productCode: insuranceProduct?.code,
    }
  );

  const storeSelectedMainRisks = useCallback((data: string[]) => {
    dispatch({
      type: NSActionTypes.SetSelectedMainRisks,
      payload: data,
    });
  }, []);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const handleRiskClick = (risk: Risk) => {
    const { code, name } = risk;

    setStepUpdated();

    const newSelectedRisks = selectedMainRisks ? [...selectedMainRisks] : [];

    if (!newSelectedRisks.includes(code)) {
      newSelectedRisks.push(code);
      sendAnalyticEvent(analyticEvents.selectOptionAccident, {
        option: name,
      });
    } else {
      newSelectedRisks.splice(newSelectedRisks.indexOf(code), 1);
      sendAnalyticEvent(analyticEvents.deselectOptionAccident, {
        option: name,
      });
    }

    storeSelectedMainRisks(newSelectedRisks);
  };

  const handleKeyPressEnter = () => {
    if (selectedMainRisks && selectedMainRisks.length > 0) {
      selectedMainRisks?.length > 0 &&
        dispatch({
          type: WizardActionTypes.UpdateWantNextStep,
          payload: true,
        });
    } else {
      setIsListBoxError(true);
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [selectedMainRisks]);

  const validatePage = useCallback(
    () => !!selectedMainRisks?.length,
    [selectedMainRisks]
  );

  useNextStep(validatePage);
  useNsDraft();

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (!isLoading && res?.risks?.length) {
      dispatch({
        type: NSActionTypes.SetRisks,
        payload: res.risks,
      });
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (risks && !selectedMainRisks) {
      dispatch({
        type: NSActionTypes.SetSelectedMainRisks,
        payload: risks
          .filter((elem: Risk) => elem.binding && elem.active)
          .map((elem: Risk) => elem.code),
      });
    }
  }, [risks]);

  useEffect(() => {
    if (wantNextStep && !selectedMainRisks?.length) {
      setIsListBoxError(true);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: true,
      });
    }
  }, [wantNextStep]);

  useEffect(() => {
    if (isListBoxError && selectedMainRisks?.length) {
      setIsListBoxError(false);
      dispatch({
        type: WizardActionTypes.SetFwNavDisabled,
        payload: false,
      });
    }
  }, [isListBoxError, selectedMainRisks]);

  useEffect(() => {
    const isValid = validatePage();

    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !isValid,
    });

    setIsListBoxError(!isValid);
  }, [selectedMainRisks]);

  if (isLoading) return <Skeleton />;

  if (error)
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;

  return (
    <Container>
      <FormHead>{t('NS_FORM:headers.whatDoYouWantToProtectFrom')}</FormHead>
      <FormSubhead isError={isListBoxError}>
        {t('NS_FORM:hints.selectOptions')}
      </FormSubhead>
      <AdaptiveListWrapper>
        {(!mainRisks || mainRisks?.length === 0) && t('COMMON:errors.noData')}
        {mainRisks?.map((item) => (
          <RiskWrapper>
            <SelectorCard
              key={item.code}
              id={item.code}
              name={item.code}
              label={item.name}
              description={item.description}
              checked={!!selectedMainRisks?.includes(item.code)}
              readOnly={!item.switchability}
              onClick={() => handleRiskClick(item)}
            />
          </RiskWrapper>
        ))}
      </AdaptiveListWrapper>
    </Container>
  );
});
