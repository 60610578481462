/* eslint-disable indent */
import { useContext } from 'react';

import { Store } from '@src/store';
import { OrderRequestData } from '@src/types';
import { prepareDate } from '@src/utils';

export const useNsOrderData = () => {
  const {
    state: { stateFormNS },
  } = useContext(Store);

  const orderData: OrderRequestData = {
    productCode: stateFormNS.insuranceProduct?.code,
    contractDuration: stateFormNS.selectedDuration,
    risks: [
      ...(stateFormNS.selectedMainRisks || []),
      ...(stateFormNS.selectedAdditionalRisks || []),
    ],
    effectiveSince: prepareDate(stateFormNS.selectedDate),
    insuranceSum: String(stateFormNS.insuranceSum),
    ...(stateFormNS.promoCode &&
      stateFormNS.getPrices?.isSuccessfulPromo !== false && {
        promoCode: stateFormNS.promoCode,
      }),
  };

  return {
    orderData,
  };
};
