import { HousePresetData, InsuranceSubproducts } from '@src/store/ifl-house';
import { getPresetDefaultInsuranceSum } from '@src/utils';

export const createSubProductsFromPresetData = (
  presetData: HousePresetData,
  subproducts: InsuranceSubproducts
): InsuranceSubproducts => {
  const newSubObjects = subproducts.subObjects.map((item) => ({
    ...item,
    defaultInsuranceSum: getPresetDefaultInsuranceSum(
      presetData.subObjects.find(({ code }) => code === item.code)
        ?.defaultInsuranceSum,
      item
    ),
  }));

  return {
    ...subproducts,
    subObjects: newSubObjects,
  };
};
