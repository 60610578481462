import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Container } from '@src/components';
import type { OrderItemType, OrderItemsBlockType } from '@src/types';

import {
  OrderBlockItemHead,
  OrderBlockItemTitle,
  OrderBlockItemTitleButton,
  OrderBlockItemWrapper,
} from './components.styles';
import { OrderItem } from './order-item';

export const OrderBlockItem: FC<OrderItemsBlockType> = (item) => {
  const { t } = useTranslation();

  return (
    <Container>
      <OrderBlockItemWrapper>
        <OrderBlockItemTitle>
          <OrderBlockItemHead>{item.title}</OrderBlockItemHead>
          {item.onClick ? (
            <OrderBlockItemTitleButton onClick={item.onClick}>
              {t('COMMON:labels.change')}
            </OrderBlockItemTitleButton>
          ) : null}
        </OrderBlockItemTitle>
        {item.value
          ?.filter((subItem) => subItem.value)
          ?.map((filteredSubItem: OrderItemType) => (
            <OrderItem key={uuid()} {...filteredSubItem} />
          ))}
      </OrderBlockItemWrapper>
    </Container>
  );
};
