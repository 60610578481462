/* eslint-disable indent */
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import type { ChangeEvent, VFC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutocompleteSelect } from '@pulse-web-ui/autocomplete-select';
import { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { TriggerDirection } from '@pulse-web-ui/range-input';

import { StyledDaDataAddressSuggestion } from '@src/components';
import { DEFAULT_FIAS_ID, Product, addressRegEx } from '@src/constants';
import { useRequest } from '@src/hooks';
import { Store, StoreActions } from '@src/store';

import { EmptyMessage } from './components/empty-message';
import {
  cityDefaultValue,
  emptyHouseSuggestionsActions,
  emptyStreetSuggestionsActions,
  flatDefaultValue,
  houseDefaultValue,
  setCustomHouseActions,
  setCustomStreetActions,
  setDadaValueActions,
  settlementDefaultValue,
  streetDefaultValue,
} from './constants';
import {
  getConnectedAddress,
  getFormDefaultValues,
  splitAddress,
} from './index';
import { StyledAddressBox, StyledAddressWrapper } from './smart-address.styles';
import { SmartAddressProps } from './smart-address.types';

export const SmartAddress: VFC<SmartAddressProps> = memo(
  ({
    type,
    handleRegionChange,
    selectedRegion,
    regions,
    dadaValue,
    setHouseSelectedStatus,
    isSelectedCustomStreet,
    isSelectedCustomHouse,
    isEmptyStreetSuggestions,
    isEmptyHouseSuggestions,
  }) => {
    const { t } = useTranslation();
    const {
      control,
      formState: { errors },
      setValue,
      watch,
      reset,
      setError,
      resetField,
      trigger,
      getFieldState,
      clearErrors,
    } = useFormContext();
    const [isShowSuggestions, setIsShowSuggestions] = useState(false);
    const [isCheckHouseStatus, setIsCheckHouseStatus] = useState(true);
    const [isFocusedStreet, setIsFocusedStreet] = useState(false);
    const [isFocusedHouse, setIsFocusedHouse] = useState(false);
    const {
      state: {
        stateAuth: { authTokens },
      },
      dispatch,
    } = useContext(Store);
    const [isRegionError, setIsRegionError] = useState(false);
    const watchStreet = watch('street');
    const watchHouse = watch('house');
    const actionType = setDadaValueActions[type] as keyof StoreActions;

    const {
      error,
      res: dadataRes,
      isLoading,
      refetch,
    } = useRequest(
      'getRegion',
      'post',
      '/v3/dadata/suggest/address',
      {
        query: selectedRegion?.region || '',
        from_bound: { value: 'region' },
        to_bound: { value: 'region' },
      },
      [selectedRegion],
      true,
      authTokens?.authorization?.accessToken
    );

    useEffect(() => {
      const resetValues = getFormDefaultValues(
        selectedRegion?.region || '',
        dadaValue
      );

      reset(resetValues);
    }, [dadaValue, selectedRegion]);

    useEffect(() => {
      if (selectedRegion && !dadaValue) {
        refetch();
      }
    }, [selectedRegion]);

    if (
      errors?.city &&
      (dadaValue?.data.city_fias_id || dadaValue?.data.settlement_fias_id)
    ) {
      clearErrors('city');
    }

    useEffect(() => {
      return () => {
        getFieldState('region')?.error && handleRegionChange(undefined);
      };
    }, []);

    useEffect(() => {
      if (!isLoading && dadataRes && !dadaValue) {
        let regionDadataValue = dadataRes.suggestions;

        if (Array.isArray(dadataRes.suggestions)) {
          regionDadataValue = dadataRes.suggestions[0];
        }

        dispatch({
          type: actionType,
          payload: {
            ...regionDadataValue,
            data: {
              ...regionDadataValue.data,
              ...cityDefaultValue,
              ...settlementDefaultValue,
              ...streetDefaultValue,
              ...houseDefaultValue,
              ...flatDefaultValue,
            },
          },
        });
      }
    }, [isLoading, dadataRes]);

    const isShowStreetHelperText =
      !!watchStreet?.length && !dadaValue?.data.street_fias_id;

    const isShowHouseHelperText =
      !!watchHouse?.length &&
      !dadaValue?.data.house_fias_id &&
      !dadaValue?.data.house_type;

    useEffect(() => {
      if (
        isCheckHouseStatus &&
        (isShowStreetHelperText || isShowHouseHelperText)
      ) {
        setHouseSelectedStatus(true);
        setIsCheckHouseStatus(false);
      }
    }, [dadaValue, isCheckHouseStatus]);

    useEffect(() => {
      if (dadaValue?.data.street_fias_id && isSelectedCustomStreet) {
        setIsSelectedCustomStreet(false);
      }
      if (dadaValue?.data.house_fias_id && isSelectedCustomHouse) {
        setIsSelectedCustomHouse(false);
      }
    }, [isSelectedCustomStreet, isSelectedCustomHouse, dadaValue]);

    const setIsSelectedCustomStreet = useCallback((data: boolean) => {
      dispatch({
        type: setCustomStreetActions[type] as keyof StoreActions,
        payload: data,
      });
    }, []);

    const setIsSelectedCustomHouse = useCallback((data: boolean) => {
      dispatch({
        type: setCustomHouseActions[type] as keyof StoreActions,
        payload: data,
      });
    }, []);

    const setDadataValue = (data?: DaDataAddressSuggestion) => {
      dispatch({
        type: actionType,
        payload: data && {
          ...data,
          unrestricted_value: getConnectedAddress(data),
          value: getConnectedAddress(data),
        },
      });
    };

    const handleOnChangeCity = (e: ChangeEvent<HTMLInputElement>) => {
      const formattedData = dadaValue && {
        ...dadaValue,
        data: {
          ...dadaValue.data,
          ...cityDefaultValue,
          ...settlementDefaultValue,
          ...streetDefaultValue,
          ...houseDefaultValue,
          ...flatDefaultValue,
          settlement_with_type: e.target.value,
        },
      };

      if (isSelectedCustomStreet) {
        setIsSelectedCustomStreet(false);
      }

      if (isSelectedCustomHouse) {
        setIsSelectedCustomHouse(false);
      }

      setIsEmptyHouseSuggestions(false);
      setIsEmptyStreetSuggestions(false);
      setDadataValue(formattedData);
    };

    const handleOnChangeStreet = (e: ChangeEvent<HTMLInputElement>) => {
      const formattedData = dadaValue && {
        ...dadaValue,
        data: {
          ...dadaValue.data,
          ...streetDefaultValue,
          ...houseDefaultValue,
          ...flatDefaultValue,
          street_with_type: e.target.value,
        },
      };

      setIsCheckHouseStatus(false);
      setIsEmptyHouseSuggestions(false);
      setIsEmptyStreetSuggestions(false);
      setIsSelectedCustomStreet(false);
      setDadataValue(formattedData);
    };

    const handleOnBlurHouse = (e: ChangeEvent<HTMLInputElement>) => {
      setIsCheckHouseStatus(true);
      setIsFocusedHouse(false);
      if (!e.target.value) {
        setError('house', {
          type: 'string',
          message: 'INSURANCE_FORMS:errors.specifyHouse',
        });
      }
    };

    const handleOnChangeHouse = (e: ChangeEvent<HTMLInputElement>) => {
      const formattedData = dadaValue && {
        ...dadaValue,
        data: {
          ...dadaValue.data,
          ...houseDefaultValue,
          ...flatDefaultValue,
          house: e.target.value,
        },
      };

      setIsCheckHouseStatus(false);
      setIsSelectedCustomHouse(false);
      setDadataValue(formattedData);
    };

    const handleOnChangeFlat = (val: string) => {
      if (!val || (val.length <= 20 && addressRegEx.test(val))) {
        const formattedData = dadaValue && {
          ...dadaValue,
          data: {
            ...dadaValue.data,
            ...flatDefaultValue,
            flat: val,
          },
        };

        clearErrors('flat');

        setDadataValue(formattedData);
      }
    };

    const setIsEmptyStreetSuggestions = useCallback(
      (status: boolean) => {
        if (isEmptyStreetSuggestions !== status) {
          dispatch({
            type: emptyStreetSuggestionsActions[type] as keyof StoreActions,
            payload: status,
          });
        }
      },
      [isEmptyStreetSuggestions]
    );

    const setIsEmptyHouseSuggestions = useCallback(
      (status: boolean) => {
        if (isEmptyHouseSuggestions !== status) {
          dispatch({
            type: emptyHouseSuggestionsActions[type] as keyof StoreActions,
            payload: status,
          });
        }
      },
      [isEmptyHouseSuggestions]
    );

    return (
      <StyledAddressWrapper>
        <Controller
          control={control}
          name="region"
          render={({ field, fieldState }) => (
            <HelperText
              status={fieldState.error || isRegionError ? 'error' : 'default'}
              message={
                isRegionError
                  ? t('INSURANCE_FORMS:errors.cannotInsureInRegion')
                  : errors.region?.message && t(errors.region.message as string)
              }
            >
              <AutocompleteSelect
                onChange={(data?: string) => {
                  handleRegionChange(data);
                  dispatch({
                    type: actionType,
                    payload: undefined,
                  });
                  setValue(field.name, data || '');
                  trigger('region');
                  setIsRegionError(false);
                  setHouseSelectedStatus(false);
                }}
                fieldOnChange={(data) => {
                  setValue(field.name, data || '');
                  resetField('region', { defaultValue: data });
                  setIsRegionError(false);
                  dispatch({
                    type: actionType,
                    payload: undefined,
                  });
                  setHouseSelectedStatus(false);
                }}
                value={selectedRegion?.region || ''}
                options={regions}
                label={t('COMMON:placeholders.selectRegion') || ''}
                status={fieldState.error || isRegionError ? 'error' : undefined}
                onBlur={(e) => {
                  const isError =
                    !!e.target.value &&
                    !regions?.some(({ label }) => label === e.target.value);
                  setIsRegionError(isError);
                  isError && setError('region', {});
                }}
              />
            </HelperText>
          )}
        />
        <Controller
          control={control}
          name="city"
          render={({ field, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={
                !isShowSuggestions &&
                errors.city?.message &&
                t(errors.city.message as string)
              }
            >
              <StyledDaDataAddressSuggestion
                filterRestrictValue
                filterFromBound="city"
                filterToBound="settlement"
                value={splitAddress(field.name, dadaValue)}
                setIsShowSuggestions={setIsShowSuggestions}
                filterLocations={[{ fias_id: selectedRegion?.region }]}
                onChange={(data) => {
                  dispatch({
                    type: actionType,
                    payload: data,
                  });
                  setHouseSelectedStatus(false);
                }}
                setIsTypingStatus={(value: string) => value.length <= 100}
                uid="dadata-address-city"
                inputProps={{
                  ...field,
                  error: !!errors.city && !isShowSuggestions,
                  label: t('COMMON:labels.locality') || '',
                  disabled: !selectedRegion || isRegionError,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    handleOnChangeCity(e);
                    field.onChange(e);
                  },
                }}
              />
            </HelperText>
          )}
        />
        <Controller
          control={control}
          name="street"
          render={({ field, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={
                (isShowStreetHelperText &&
                  !isEmptyStreetSuggestions &&
                  !isFocusedStreet &&
                  !isSelectedCustomStreet &&
                  t('COMMON:hints.noStreet')) ||
                (errors.street?.message && t(errors.street.message as string))
              }
            >
              <StyledDaDataAddressSuggestion
                filterRestrictValue
                filterFromBound="street"
                filterToBound="street"
                value={splitAddress(field.name, dadaValue)}
                setIsEmptySuggestions={setIsEmptyStreetSuggestions}
                filterLocations={[
                  {
                    fias_id:
                      dadaValue?.data?.settlement_fias_id ||
                      dadaValue?.data?.city_fias_id,
                  },
                ]}
                onChange={(data) => {
                  dispatch({
                    type: actionType,
                    payload: data,
                  });
                  setHouseSelectedStatus(false);
                }}
                setIsTypingStatus={(value: string) =>
                  value.length <= 100 && addressRegEx.test(value)
                }
                uid="dadata-address-street"
                inputProps={{
                  ...field,
                  label: t('COMMON:labels.street') || '',
                  disabled:
                    !dadaValue?.data.settlement_fias_id &&
                    !dadaValue?.data.city_fias_id,
                  onBlur: () => {
                    setIsCheckHouseStatus(true);
                    setIsFocusedStreet(false);
                    field.onBlur();
                  },
                  onFocus: () => setIsFocusedStreet(true),
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    handleOnChangeStreet(e);
                    field.onChange(e);
                  },
                }}
                emptyMessage={
                  !dadaValue?.data.street_fias_id &&
                  watchStreet &&
                  isEmptyStreetSuggestions &&
                  !isSelectedCustomStreet && (
                    <EmptyMessage
                      title={t('INSURANCE_FORMS:errors.notFoundStreet')}
                      description={t(
                        'INSURANCE_FORMS:errors.enteredCorrectAddressContinue'
                      )}
                      buttonLabel={t('COMMON:buttons.continueWithStreet')}
                      handleOnMouseDown={() => setIsSelectedCustomStreet(true)}
                    />
                  )
                }
              />
            </HelperText>
          )}
        />
        <StyledAddressBox>
          <Controller
            control={control}
            name="house"
            render={({ field, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={
                  (errors.house?.message &&
                    t(errors.house.message as string)) ||
                  (isShowHouseHelperText &&
                    !isShowStreetHelperText &&
                    !isEmptyHouseSuggestions &&
                    !isFocusedHouse &&
                    !isSelectedCustomHouse &&
                    t('COMMON:hints.noHouse'))
                }
              >
                <StyledDaDataAddressSuggestion
                  filterRestrictValue
                  isDisableRequests={
                    isSelectedCustomStreet || isShowStreetHelperText
                  }
                  filterFromBound="house"
                  filterToBound="house"
                  value={splitAddress(field.name, dadaValue)}
                  setIsEmptySuggestions={setIsEmptyHouseSuggestions}
                  filterLocations={[
                    {
                      fias_id:
                        dadaValue?.data?.street_fias_id ||
                        dadaValue?.data?.settlement_fias_id ||
                        dadaValue?.data?.city_fias_id,
                    },
                  ]}
                  onChange={(data) => {
                    dispatch({
                      type: actionType,
                      payload: data && {
                        ...data,
                        data: {
                          ...data.data,
                          house_fias_id:
                            data.data.house_fias_id || DEFAULT_FIAS_ID,
                        },
                      },
                    });
                    if (data?.data.flat) {
                      setValue('flat', data.data.flat);
                    }
                    setHouseSelectedStatus(false);
                  }}
                  setIsTypingStatus={(value: string) =>
                    value.length <= 50 && addressRegEx.test(value)
                  }
                  uid="dadata-address-house"
                  inputProps={{
                    ...field,
                    error: !!errors.house,
                    label: t('COMMON:labels.house') || '',
                    disabled:
                      !dadaValue?.data.settlement_fias_id &&
                      !dadaValue?.data.city_fias_id,
                    onChange: handleOnChangeHouse,
                    onBlur: handleOnBlurHouse,
                    onFocus: () => setIsFocusedHouse(true),
                  }}
                  emptyMessage={
                    !isSelectedCustomStreet &&
                    !dadaValue?.data.house_fias_id &&
                    watchHouse &&
                    isEmptyHouseSuggestions &&
                    !isSelectedCustomHouse && (
                      <EmptyMessage
                        title={t('INSURANCE_FORMS:errors.notFoundHouse')}
                        description={t(
                          'INSURANCE_FORMS:errors.enteredCorrectAddressContinue'
                        )}
                        buttonLabel={t('COMMON:buttons.continueWithHouse')}
                        handleOnMouseDown={() => setIsSelectedCustomHouse(true)}
                      />
                    )
                  }
                />
              </HelperText>
            )}
          />
          <Controller
            control={control}
            name="flat"
            render={({ field: { onBlur, name }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={
                  errors.flat?.message && t(errors.flat.message as string)
                }
              >
                <Input
                  name={name}
                  onChange={handleOnChangeFlat}
                  value={splitAddress(name, dadaValue)?.value || ''}
                  label={t('COMMON:labels.flat') || ''}
                  error={!!errors.flat}
                  onBlur={onBlur}
                  disabled={!dadaValue?.data.house}
                  hasTooltip={type === Product.HOUSE}
                  tooltipText={
                    t('IFL_HOUSE_FORM:hints.ifNoApartmentFieldBlank') || ''
                  }
                  trigger={TriggerDirection.HOVER}
                  hintObject={{
                    showIcon: type === Product.HOUSE,
                  }}
                />
              </HelperText>
            )}
          />
        </StyledAddressBox>
      </StyledAddressWrapper>
    );
  }
);
