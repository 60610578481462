import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@pulse-web-ui/spinner';

import { currencyRuLocaleWithoutFraction } from '@src/utils';

import {
  StyledSpinnerWrapper,
  SumPerMonthBaseValue,
  SumPerMonthDesc,
  SumPerMonthPromoDesc,
  SumPerMonthPromoValue,
  SumPerMonthPromoWrapper,
  SumPerMonthTitle,
  SumPerMonthValue,
  SumPerMonthWrapper,
} from './sum-per-month.styles';
import type { SumPerMonthProps } from './sum-per-month.types';

export const SumPerMonth: FC<SumPerMonthProps> = ({
  isLoading,
  disabled,
  sumPerMonth,
  sumPromoPerMonth,
  sumPerMonthDesc,
  title,
  description,
  isError,
}) => {
  const { t } = useTranslation();

  return (
    <SumPerMonthWrapper disabled={disabled} isError={isError}>
      <SumPerMonthTitle>
        {title || t('COMMON:labels.insuranceCostPerMonth')}
      </SumPerMonthTitle>
      {isLoading || !sumPerMonth ? (
        <StyledSpinnerWrapper>
          <Spinner inverse={false} />
        </StyledSpinnerWrapper>
      ) : sumPromoPerMonth ? (
        <>
          <SumPerMonthPromoWrapper>
            <SumPerMonthPromoValue>
              {currencyRuLocaleWithoutFraction(sumPromoPerMonth)} &#8381;
            </SumPerMonthPromoValue>
            {sumPerMonthDesc && (
              <SumPerMonthPromoDesc> {sumPerMonthDesc}</SumPerMonthPromoDesc>
            )}
            <SumPerMonthBaseValue>
              {currencyRuLocaleWithoutFraction(sumPerMonth)} &#8381;
            </SumPerMonthBaseValue>
          </SumPerMonthPromoWrapper>
          {description && <SumPerMonthDesc>{description}</SumPerMonthDesc>}
        </>
      ) : (
        <>
          <SumPerMonthValue>
            {currencyRuLocaleWithoutFraction(sumPerMonth)} &#8381;{' '}
            {sumPerMonthDesc && sumPerMonthDesc}
          </SumPerMonthValue>
          {description && <SumPerMonthDesc>{description}</SumPerMonthDesc>}
        </>
      )}
    </SumPerMonthWrapper>
  );
};
