import { Button } from '@pulse-web-ui/button';
import { css, media, mixins, styled, typography } from '@pulse-web-ui/theme';

export const PhoneVerificationContainer = styled.div`
  ${media.desktop} {
    width: calc(50% - 16px);
  }
`;

export const CodeContainer = styled.div`
  ${mixins.flex({})};
`;

export const CodeInputWrapper = styled.div`
  width: calc(100% - 56px);
`;

export const ReturnToPhoneChangeButton = styled.div`
  ${typography.regular16};
  color: ${({ theme }) => theme.colors.text.interactive};
  cursor: pointer;
  margin-top: 8px;
`;

export const StyledButton = styled(Button)<{
  error?: string;
}>`
  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.colors.text.error};
      background: ${theme.colors.text.error};
      &:hover {
        background: ${theme.colors.text.error} !important;
        border: 1px solid ${theme.colors.text.error} !important;
      }
    `}
`;
