import type { Reducer } from 'react';
import { Dispatch } from 'react';

import { authStoreReducer } from '@src/store/authorize';
import { draftStoreReducer } from '@src/store/draft';
import { formIFLFlatStoreReducer } from '@src/store/ifl-flat';
import { formIFLHouseStoreReducer } from '@src/store/ifl-house';
import type { StoreActions } from '@src/store/index';
import { StoreState } from '@src/store/index';
import { miteStoreReducer } from '@src/store/mite';
import { formNSStoreReducer } from '@src/store/ns';
import { orderStoreReducer } from '@src/store/order';
import { formPetsStoreReducer } from '@src/store/pets';
import { userStoreReducer } from '@src/store/user';
import { wizardStoreReducer } from '@src/store/wizard';

import { authFlowStoreReducer } from './auth-flow';
import { formSportNSStoreReducer } from './sport/index';

export type StoreDispatch = Dispatch<StoreActions>;

export const storeReducer: Reducer<StoreState, StoreActions> = (
  {
    stateFormNS,
    stateMite,
    stateWizard,
    stateAuth,
    stateUser,
    stateOrder,
    stateFormIFLHouse,
    stateFormIFLFlat,
    stateFormPets,
    stateFormNSSport,
    stateDraft,
    stateAuthFlow,
  },
  action
) => {
  return {
    stateFormIFLHouse: formIFLHouseStoreReducer(stateFormIFLHouse, action),
    stateFormIFLFlat: formIFLFlatStoreReducer(stateFormIFLFlat, action),
    stateFormNS: formNSStoreReducer(stateFormNS, action),
    stateWizard: wizardStoreReducer(stateWizard, action),
    stateAuth: authStoreReducer(stateAuth, action),
    stateUser: userStoreReducer(stateUser, action),
    stateOrder: orderStoreReducer(stateOrder, action),
    stateFormPets: formPetsStoreReducer(stateFormPets, action),
    stateFormNSSport: formSportNSStoreReducer(stateFormNSSport, action),
    stateMite: miteStoreReducer(stateMite, action),
    stateDraft: draftStoreReducer(stateDraft, action),
    stateAuthFlow: authFlowStoreReducer(stateAuthFlow, action),
  };
};
