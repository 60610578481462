import React, { useState } from 'react';

import { ArrowDown, ArrowUp } from '@pulse-web-ui/icons';

import { OrderItemAccordionType } from '@src/types';

import {
  AccordionArrowIcon,
  AccordionToggle,
  OrderItemTitle,
  OrderItemValue,
  OrderItemWrapper,
} from './components.styles';

export const OrderAccordionItem = ({
  title,
  value,
  accordionTitle,
}: OrderItemAccordionType) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OrderItemWrapper>
      <AccordionToggle onClick={() => setIsOpen(!isOpen)}>
        <OrderItemWrapper>
          <OrderItemTitle>{title}</OrderItemTitle>
          <OrderItemValue>{accordionTitle}</OrderItemValue>
          <AccordionArrowIcon>
            {isOpen ? <ArrowUp width={20} /> : <ArrowDown width={20} />}
          </AccordionArrowIcon>
        </OrderItemWrapper>
      </AccordionToggle>
      {isOpen && <div>{value}</div>}
    </OrderItemWrapper>
  );
};
