import { InsurePerson, OrderRequestDataPerson } from '@src/types';
import { calculateAge } from '@src/utils';

export const orderRequestPersonsData = (
  insurePersons: InsurePerson[]
): OrderRequestDataPerson[] => {
  return insurePersons.map((person) => {
    const {
      firstName,
      middleName,
      lastName,
      birthDate,
      ageMin,
      primaryRecordId,
    } = person;

    return {
      firstName: firstName?.trim(),
      middleName: middleName?.trim(),
      lastName: lastName?.trim(),
      ageMin,
      primaryRecordId,
      birthDate: birthDate
        ? new Date(new Date(birthDate).setUTCHours(+24)).toISOString()
        : undefined,
      age: birthDate ? calculateAge(new Date(birthDate)) : undefined,
    };
  });
};
