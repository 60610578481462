import { memo, useContext } from 'react';

import { BaseLayout } from '@src/layouts';
import { Store } from '@src/store';

import { ScoreErrorContent } from './score-error-content';

export const ScoreErrorPage = memo(() => {
  const {
    state: {
      stateFormIFLFlat: { isObjectDataError },
      stateFormIFLHouse: { isObjectDataError: isObjectHouseDataError },
      stateUser: { isScrinning },
    },
  } = useContext(Store);

  return (
    <BaseLayout>
      <ScoreErrorContent
        isObjectDataError={isObjectDataError || isObjectHouseDataError}
        isScrinning={isScrinning}
      />
    </BaseLayout>
  );
});
