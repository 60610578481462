/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash.isequal';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Button } from '@pulse-web-ui/button';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import { PageBackButton } from '@src/common-components/button';
import {
  ArrowLeftIcon2,
  Container,
  FormSubTitle,
  OrderSubTitle,
  OrderTitle,
  Skeleton,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import {
  Product,
  UseQueryStatus,
  analyticEvents,
  iflFlatRoute,
  iflHouseRoute,
  iflRoute,
  miteRoute,
  nsRoute,
  petsRoute,
  sportNsRoute,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import {
  useHandlePressKey,
  useRequest,
  useValidateProfileAuth,
  useWebanalyticParams,
} from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { formOrderDetailSchema } from '@src/schemas';
import { OrderActionTypes, Store, UserActionTypes } from '@src/store';
import {
  KeyCode,
  OrderItemAccordionType,
  OrderItemType,
  OrderItemValueType,
  OrderItemsBlockType,
  OrderRequestData,
  SubmitData,
} from '@src/types';
import { handleAnalyticsEvents } from '@src/utils';

import { changeMiteUserPrimaryRecordId } from '../mite/utils';
import { changeNsUserPrimaryRecordId } from '../ns-form/utils';
import { usePetsOrderArray } from '../pets-form/hooks/use-pets-order-array';
import { changeSportUserPrimaryRecordId } from '../sport-form/utils';
import {
  EmailWrapper,
  OrderContentWrapper,
  OrderPageTitleWrapper,
} from './components/components.styles';
import { OrderAccordionItem } from './components/order-accordion-item';
import { OrderBlockItem } from './components/order-block-item';
import { OrderItem } from './components/order-item';

const getUrl = (authorizeRefRoute: string | undefined) => {
  switch (authorizeRefRoute) {
    case sportNsRoute:
      return '/v3/subscription/submit/sport';

    case miteRoute:
      return '/v3/subscription/submit/antimite';

    default:
      return '/v1/subscription/submit';
  }
};

export const OrderDetail: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: { profile, preset, selectedProduct },
      stateOrder: {
        order,
        orderSubmitData,
        orderPageTitle,
        orderRequestData,
        cachedOrderRequestData,
      },
      stateAuth: { authTokens, authorizeRefRoute },
      stateWizard: { currentStep },
    },
    dispatch,
  } = useContext(Store);
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  const [shouldUpdateProfile, setShouldUpdateProfile] =
    useState<boolean>(false);
  const [shouldNavigateCheckout, setShouldNavigateCheckout] =
    useState<boolean>(false);
  const [updatedEmail, setUpdatedEmail] = useState<string | undefined | null>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(formOrderDetailSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      email: profile?.profile.email,
    },
  });

  const { profileRefetch, profileIsLoading, profileError } =
    useValidateProfileAuth();

  const setPetsOrderArray = usePetsOrderArray();

  useEffect(() => {
    if (!profile?.profile.lastName) {
      profileRefetch();
    }
  }, []);

  useEffect(() => {
    if (!!profile) {
      setValue('email', profile?.profile.email);

      if (selectedProduct === Product.PETS) {
        setPetsOrderArray();
      }
    }
  }, [profile]);

  const orderSubTitle = useMemo(() => {
    switch (authorizeRefRoute) {
      case petsRoute:
        return t('ORDER:labels.checkThatEverythingCorrect');
      default:
        return t('ORDER:labels.checkIfEverythingCorrect');
    }
  }, [authorizeRefRoute]);

  const orderAdditionalSubTitle = useMemo(() => {
    switch (authorizeRefRoute) {
      case iflRoute:
      case iflHouseRoute:
      case iflFlatRoute:
      case nsRoute:
      case miteRoute:
        return t('ORDER:labels.youCanChangeTermsOfSubscription');
      case petsRoute:
        return '';
      default:
        return t('ORDER:labels.youCanChangeTermsOfSubscription');
    }
  }, [authorizeRefRoute]);

  const pageTitle = useMemo(() => {
    switch (authorizeRefRoute) {
      case petsRoute:
        return t('ORDER:headers.yourSubscriptionIsReady');
      case sportNsRoute:
        return t('ORDER:headers.policyIsReady');
      case miteRoute:
        return t('MITE_FORM:headers.policyIsReady');
      default:
        return t('ORDER:headers.subscriptionIsReady');
    }
  }, [authorizeRefRoute]);

  const pageSubTitle = useMemo(() => {
    switch (authorizeRefRoute) {
      case sportNsRoute:
      case miteRoute:
        return null;

      case iflFlatRoute:
        return <> {orderAdditionalSubTitle}</>;

      default:
        return (
          <>
            <br />
            {orderAdditionalSubTitle}
          </>
        );
    }
  }, [authorizeRefRoute]);
  useEffect(() => {
    handleAnalyticsEvents.handleOrderAnalyticsEvents({
      authorizeRefRoute,
      preset,
      profile: profile?.profile,
    });
  }, [profile?.profile.lastName]);

  const navigate = useNavigate();

  const { isLoading, error, res, refetch, status } = useRequest(
    'formIFLSubscriptionSubmit',
    'post',
    getUrl(authorizeRefRoute),
    orderRequestData,
    [orderSubmitData, orderRequestData, updatedEmail],
    true,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: updateProfileIsLoading,
    error: updateProfileError,
    res: updateProfileRes,
    refetch: updateProfileRefetch,
  } = useRequest(
    'updateProfileRequest',
    'post',
    '/v1/user/update-profile',
    {
      clientChange: {
        email: updatedEmail,
      },
    },
    [updatedEmail, authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  const handleClickBack = () => {
    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPreviousRealty, {
          screen: currentStep + 1,
        });
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPreviousAccident, {
          screen: currentStep + 1,
        });
        break;
      // TODO: добавить аналитику питомцев
    }

    navigate(`/${selectedProduct}`);
  };

  const onSubmitPageHandler = handleSubmit((data) => {
    localStorage.setItem('email', data?.email || profile?.profile.email || '');

    if (!profile?.profile.email || profile?.profile.email !== data.email) {
      setUpdatedEmail(data.email);
      setShouldUpdateProfile(true);

      updateProfileRefetch();
    } else {
      setShouldSubmit(true);
      setShouldNavigateCheckout(true);
    }

    switch (authorizeRefRoute) {
      case iflRoute:
        sendAnalyticEvent(analyticEvents.toPurchaseRealty);
        break;
      case nsRoute:
        sendAnalyticEvent(analyticEvents.toPurchaseAccident);
        break;
      // TODO: добавить аналитику питомцев
    }
  });

  const handleKeyPressEnter = () => onSubmitPageHandler();
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter);

  useEffect(() => {
    if (!updateProfileIsLoading && updateProfileRes) {
      setShouldUpdateProfile(false);

      dispatch({
        type: UserActionTypes.SetProfile,
        payload: updateProfileRes,
      });

      if (
        authorizeRefRoute === sportNsRoute ||
        authorizeRefRoute === nsRoute ||
        authorizeRefRoute === miteRoute
      ) {
        const newOrderRequestData =
          authorizeRefRoute === sportNsRoute
            ? changeSportUserPrimaryRecordId(
                orderRequestData as SubmitData,
                updateProfileRes.profile.primaryRecordId
              )
            : authorizeRefRoute === miteRoute
            ? changeMiteUserPrimaryRecordId(
                orderRequestData as SubmitData,
                updateProfileRes.profile.primaryRecordId
              )
            : changeNsUserPrimaryRecordId(
                orderRequestData as OrderRequestData,
                updateProfileRes.profile.primaryRecordId
              );

        dispatch({
          type: OrderActionTypes.SetOrderRequestData,
          payload: newOrderRequestData,
        });
      } else {
        setShouldSubmit(true);
        setShouldNavigateCheckout(true);
      }
    }
  }, [updateProfileIsLoading, updateProfileRes]);

  useEffect(() => {
    if (!isLoading && res) {
      setShouldSubmit(false);
      dispatch({
        type: OrderActionTypes.SetCachedOrderRequestData,
        payload: orderRequestData,
      });

      dispatch({
        type: OrderActionTypes.SetOrderSubmitData,
        payload: res,
      });
    }
  }, [res]);

  useEffect(() => {
    if (error && !res) {
      const e = error?.response?.status;

      if (e === 400 && error?.response?.data?.code === 'USER_DATA_ERROR') {
        dispatch({
          type: UserActionTypes.SetIsScrinning,
          payload: true,
        });

        navigate('/score-error');
      }
    }
  }, [error, res]);

  useEffect(() => {
    if (updateProfileError && !updateProfileRes) {
      if (updateProfileError?.response?.data?.code === 'NOT_UNIQUE_EMAIL') {
        setError('email', {
          type: 'string',
          message: 'COMMON:errors.emailAlreadyExist',
        });
      }
    }
  }, [updateProfileError, updateProfileRes]);

  useEffect(() => {
    if (!order && selectedProduct !== Product.PETS) {
      navigate(authorizeRefRoute ? authorizeRefRoute : '/');
    }
  }, [order]);

  useWebanalyticParams();

  useEffect(() => {
    if (updatedEmail) {
      setShouldSubmit(true);
      setShouldNavigateCheckout(true);
    }
  }, [orderRequestData]);

  useEffect(() => {
    if (
      (shouldSubmit &&
        !isLoading &&
        !isEqual(orderRequestData, cachedOrderRequestData)) ||
      shouldUpdateProfile
    ) {
      setShouldSubmit(false);
      setShouldUpdateProfile(false);

      if (shouldUpdateProfile) {
        if (
          !updateProfileIsLoading &&
          updateProfileRes &&
          !updateProfileError
        ) {
          refetch();
        }
      } else if (
        status === UseQueryStatus.IDLE ||
        status === UseQueryStatus.ERROR
      ) {
        refetch();
      }
    } else if (shouldNavigateCheckout && !isLoading && !error) {
      setShouldNavigateCheckout(false);

      navigate('/order-checkout');
    }
  }, [
    shouldUpdateProfile,
    updateProfileIsLoading,
    updateProfileRes,
    updateProfileError,
    shouldSubmit,
    shouldNavigateCheckout,
    isLoading,
  ]);

  const Footer = () => (
    <>
      <Button
        label={t('COMMON:buttons.confirm') || ''}
        variant="primary"
        onClick={onSubmitPageHandler}
        disabled={
          isLoading || updateProfileIsLoading || !!Object.keys(errors)?.length
        }
      />
    </>
  );

  if (error) {
    const e = error?.response?.status;

    if (e !== 401) {
      return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
    }
  }

  if (updateProfileError) {
    const response = updateProfileError?.response;

    if (
      response?.status !== 401 &&
      response?.data?.code !== 'NOT_UNIQUE_EMAIL'
    ) {
      return (
        <GlobalErrorInfo
          pending={updateProfileIsLoading}
          retrayHandler={updateProfileRefetch}
        />
      );
    }
  }

  if (profileError) {
    const profileErrorStatus = profileError?.response?.status;

    if (profileErrorStatus !== 401 && profileErrorStatus !== 403) {
      return (
        <GlobalErrorInfo
          pending={profileIsLoading}
          retrayHandler={profileRefetch}
        />
      );
    }
  }

  if (updateProfileIsLoading || isLoading || profileIsLoading) {
    return <Skeleton />;
  }

  return (
    <BaseLayout footer={<Footer />}>
      <PageBackButton
        variant="text"
        icon={<ArrowLeftIcon2 />}
        onClick={handleClickBack}
      >
        {t('COMMON:buttons.back')}
      </PageBackButton>
      <Container>
        {/* <OrderHeader bgUrl={bgUrl}> // TODO: добавить цветные шапки когда будем делать темизацию */}
        <OrderTitle>
          {profile?.profile.firstName}, {pageTitle}
        </OrderTitle>
        <OrderSubTitle>
          {orderSubTitle}
          {pageSubTitle}
        </OrderSubTitle>
        {/* </OrderHeader> */}
      </Container>
      {orderPageTitle && (
        <Container>
          <OrderPageTitleWrapper>{orderPageTitle}</OrderPageTitleWrapper>
        </Container>
      )}
      <OrderContentWrapper>
        {(order as any[])
          ?.filter(
            (
              item: OrderItemType | OrderItemsBlockType | OrderItemAccordionType
            ) => item.value
          )
          ?.map((orderItem: OrderItemsBlockType | OrderItemType) =>
            orderItem.type === OrderItemValueType.BLOCK ? (
              <OrderBlockItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemsBlockType)}
              />
            ) : orderItem.type === OrderItemValueType.ACCORDION ? (
              <OrderAccordionItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemAccordionType)}
              />
            ) : (
              <OrderItem
                key={uuid()}
                {...(orderItem as unknown as OrderItemType)}
              />
            )
          )}
        <EmailWrapper>
          <FormSubTitle>{t('COMMON:labels.sendPolicyToMail')}</FormSubTitle>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={errors.email?.message && t(errors.email.message)}
              >
                <Input
                  name="email"
                  value={value || ''}
                  label={t('COMMON:labels.email') || ''}
                  onChange={(value) => {
                    onChange(value.toLocaleLowerCase());
                  }}
                  onBlur={onBlur}
                  error={!!errors.email}
                />
              </HelperText>
            )}
          />
        </EmailWrapper>
      </OrderContentWrapper>
    </BaseLayout>
  );
};
