import { media, rgba, styled, useTheme } from '@pulse-web-ui/theme';

export const OtpContainer = styled.div`
  margin-bottom: 56px;
  ${media.desktop} {
    margin-bottom: 8px;
  }

  .otp-input {
    input {
      transition: border-color 0.2s ease-in-out;

      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.neutral[30]}!important;
      }
    }
  }
`;

export const otpInputStyle = () => {
  const theme: any = useTheme();

  return {
    width: 48,
    height: 56,
    marginRight: 8,
    boxSizing: 'border-box',
    borderRadius: 4,
    textAlign: 'center',
    outline: 'none',
    border: `1px solid ${rgba(theme.colors.neutral[100], 0.2)}`,
    fontFamily: 'Roboto Flex',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px',
    color: `${theme.colors.text.primary}`,
  };
};

export const otpErrorStyle = () => {
  const theme: any = useTheme();

  return {
    borderColor: `${theme.colors.accent.error.normal}`,
    backgroundColor: `${theme.colors.neutral[0]}`,
    color: `${rgba(theme.colors.text.primary, 0.4)}`,
  };
};
