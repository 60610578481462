import type { FC } from 'react';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';

import { Skeleton } from '@src/components';
import { insuranceProductsCode } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useRequest } from '@src/hooks';
import { BaseLayout } from '@src/layouts';
import { DraftActionTypes, PetsActionTypes, Store } from '@src/store';
import type { PetsStoreState } from '@src/store';
import type { InsuranceProductType } from '@src/types';
import { cleartDraft, getDraft } from '@src/utils';

import { PetsForm } from './pets-form';

export const PetsPage: FC = () => {
  const {
    state: {
      stateDraft: { checkDraft },
      stateUser: { selectedProduct, profile },
      stateFormPets: { presetData },
    },
    dispatch,
  } = useContext(Store);

  const [isWait, setIsWait] = useState(true);

  const {
    isLoading: productIsLoading,
    error: productError,
    res: resProduct,
    refetch: productRefetch,
  } = useRequest(
    'formPetsGetInsuranceProduct',
    'post',
    '/v1/references/get-insurance-product',
    {
      productCode: insuranceProductsCode.pets,
    }
  );

  useLayoutEffect(() => {
    if (!productIsLoading && resProduct) {
      const data: InsuranceProductType = { ...resProduct };

      dispatch({
        type: PetsActionTypes.SetSelectedIProduct,
        payload: data,
      });
    }
  }, [productIsLoading, resProduct]);

  useEffect(() => {
    dispatch({
      type: DraftActionTypes.SetCheckDraft,
      payload: !presetData,
    });
  }, []);

  useEffect(() => {
    if (checkDraft && selectedProduct && !productIsLoading && resProduct) {
      dispatch({
        type: DraftActionTypes.SetCheckDraft,
        payload: false,
      });
      const { resData } = getDraft();

      if (resData) {
        if (resData.common.productType === resProduct.code) {
          const state = resData.state as unknown as PetsStoreState;

          dispatch({
            type: PetsActionTypes.SetSelectedIProduct,
            payload: state?.selectedIProduct,
          });
          dispatch({
            type: PetsActionTypes.SetRisks,
            payload: state?.risks,
          });
          dispatch({
            type: PetsActionTypes.SetInsuranceSum,
            payload: state?.insuranceSum || 20000,
          });
          dispatch({
            type: PetsActionTypes.SetGetPrices,
            payload: state?.getPrices,
          });
          dispatch({
            type: PetsActionTypes.SetSelectedDuration,
            payload: state?.selectedDuration,
          });
          dispatch({
            type: PetsActionTypes.SetSelectedDate,
            payload:
              state?.selectedDate && state?.selectedDate !== null
                ? new Date(state?.selectedDate as unknown as string)
                : null,
          });
          dispatch({
            type: PetsActionTypes.SetPromoCode,
            payload: state?.promoCode,
          });
          dispatch({
            type: PetsActionTypes.SetRisksPage,
            payload: state?.risksPage || 'primary',
          });
          dispatch({
            type: PetsActionTypes.SetPets,
            payload: state?.pets || [],
          });
          dispatch({
            type: PetsActionTypes.SetSelectedPetData,
            payload: state?.selectedPetData,
          });
          dispatch({
            type: PetsActionTypes.SetSublimits,
            payload: state?.sublimits,
          });
          dispatch({
            type: PetsActionTypes.SetSelectedPetKindName,
            payload: state?.selectedPetKindName,
          });
          dispatch({
            type: PetsActionTypes.SetCurrentSum,
            payload: state?.currentSum || 250_000,
          });
          dispatch({
            type: PetsActionTypes.SetCurrentSumMinLimit,
            payload: state?.currentSumMinLimit || '0',
          });
          dispatch({
            type: PetsActionTypes.SetCurrentSumMaxLimit,
            payload: state?.currentSumMaxLimit || '0',
          });
          dispatch({
            type: PetsActionTypes.SetCurrentSumErr,
            payload: state?.currentSumErr || false,
          });
          dispatch({
            type: PetsActionTypes.SetSelectedId,
            payload: state?.selectedId,
          });
        } else {
          cleartDraft();
        }
      }

      setIsWait(false);
    } else if (!checkDraft) {
      setIsWait(false);
    }
  }, [checkDraft, selectedProduct, productIsLoading, resProduct, profile]);

  if (productIsLoading || isWait || checkDraft) return <Skeleton />;

  if (productError) {
    return (
      <GlobalErrorInfo
        pending={productIsLoading}
        retrayHandler={productRefetch}
      />
    );
  }

  return (
    <BaseLayout>
      <PetsForm />
    </BaseLayout>
  );
};
