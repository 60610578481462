import { Product } from '@src/constants';
import type { ComponentsConfig } from '@src/types';

import { houseComponentsConfig } from './house-components-config';
import { petsComponentsConfig } from './pets-components-config';

export const componentsConfigs: { [key in Product]?: ComponentsConfig } = {
  [Product.PETS]: petsComponentsConfig,
  [Product.HOUSE]: houseComponentsConfig,
};
