import { mixins, styled } from '@pulse-web-ui/theme';

export const SettingsWrapper = styled.div`
  ${mixins.flex({ justifyContent: 'space-between', alignItems: 'center' })}
  margin-bottom: 32px;

  h2 {
    margin-bottom: 0;
  }
`;
