/* eslint-disable indent */
import { merge } from 'lodash';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import { Spinner } from '@pulse-web-ui/spinner';
import {
  StyledThemeProvider,
  baseTheme,
  createGlobalStyle,
} from '@pulse-web-ui/theme';
import type { BaseThemeType } from '@pulse-web-ui/theme';

import { PageSpinnerWrapper } from '@src/components';
import { useThemeRequest } from '@src/hooks';
import i18n from '@src/i18n/config';
import { AuthFlowActionTypes, Store } from '@src/store';
import { AuthFlow, ThemeConfig } from '@src/types';

import { useFontLink } from './hooks';
import { FontContainer } from './theme-container.styles';

type Props = {
  themeUrl?: string;
};

type GlobalThemeProps = {
  theme: BaseThemeType;
};

const getThemeURL = (
  res: ThemeConfig | undefined,
  partnername: string | undefined,
  type: 'ui' | 'i18n' | 'auth-flow' | 'icons',
  configType: 'theme-url' | 'texts-url' | 'auth-url' | 'icons-url'
) => {
  if (res && res[configType] && partnername) {
    return `/${String(partnername)}/${type}/${String(res[configType])}.json`;
  }

  return '';
};

export const ThemeContainerIn: FC<Props> = memo(({ children, themeUrl }) => {
  const { dispatch } = useContext(Store);
  // themeUrl - составной параметр вида “partnername_customname”
  // первая часть partnername - название партнера (соответствующий каталог в CDN)
  // вторая часть customname - название конфига кастомизации (внутри на первом этапе два параметра с названием темы ui и названием коллекции текстов texts)
  // подробнее - в документации

  const [partnername, customname] = themeUrl?.split('_') || [];

  const themeLink =
    partnername &&
    customname &&
    `/${String(partnername)}/${String(customname)}-config.json`;

  const [isLoading, setIsLoading] = useState(!!themeUrl);

  const fontLink = partnername
    ? `${window.envUrls.STORAGE_URL}/themes/${String(partnername)}/fonts`
    : '';

  const { isLoading: isLoadingConfig, res: resConfig } =
    useThemeRequest<ThemeConfig>(
      'themingRequest',
      themeLink,
      [themeLink],
      !!themeLink
    );

  const { isLoading: isLoadingTheme, res: resTheme } =
    useThemeRequest<ThemeConfig>(
      'theme',
      getThemeURL(resConfig, partnername, 'ui', 'theme-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'ui', 'theme-url')
    );

  const { isLoading: isLoadingTexts, res: resTexts } =
    useThemeRequest<ThemeConfig>(
      'texts',
      getThemeURL(resConfig, partnername, 'i18n', 'texts-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'i18n', 'texts-url')
    );

  const {
    isLoading: isLoadingAuthFlow,
    res: resAuthFlow,
    status: statusAuthFLow,
  } = useThemeRequest<AuthFlow>(
    'auth-flow',
    getThemeURL(resConfig, partnername, 'auth-flow', 'auth-url'),
    [resConfig, partnername],
    !!getThemeURL(resConfig, partnername, 'auth-flow', 'auth-url')
  );

  const { isLoading: isLoadingIcons, res: resIcons } =
    useThemeRequest<AuthFlow>(
      'icons',
      getThemeURL(resConfig, partnername, 'icons', 'icons-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'icons', 'icons-url')
    );

  const customTheme = useMemo(() => {
    if (!isLoading && resTheme) {
      return merge(baseTheme, resTheme, resIcons || {});
    } else {
      return baseTheme;
    }
  }, [isLoading, resTheme, resIcons]);

  useFontLink(fontLink, customTheme);

  const GlobalStyle = createGlobalStyle`
    body {
      ${({ theme }: GlobalThemeProps) => {
        if (theme.common['font-url'])
          return `
          * {
            font-family: ${theme.common['font-url']}
          }`;
        else if (theme.common['font-family']) {
          return `
          * {
            font-family: ${theme.common['font-family']}
          }`;
        }
        return '';
      }};
    };
  `;

  useEffect(() => {
    if (resAuthFlow) {
      dispatch({
        type: AuthFlowActionTypes.SetAuthFLowParams,
        payload: resAuthFlow,
      });
    }
  }, [resAuthFlow]);

  useEffect(() => {
    dispatch({
      type: AuthFlowActionTypes.SetStatus,
      payload: statusAuthFLow,
    });
  }, [statusAuthFLow]);

  useEffect(() => {
    if (resTexts && (resTexts as any)?.ru && !isLoadingTexts) {
      Object.keys((resTexts as any).ru)?.forEach((item) => {
        if ((resTexts as any).ru[item]) {
          i18n.addResourceBundle(
            'ru',
            item,
            (resTexts as any).ru[item],
            true,
            true
          );
        }
      });
    }
  }, [resTexts, isLoadingTexts]);

  useEffect(() => {
    setIsLoading(
      isLoadingConfig ||
        isLoadingTheme ||
        isLoadingTexts ||
        isLoadingAuthFlow ||
        isLoadingIcons
    );
  }, [
    isLoadingConfig,
    isLoadingTheme,
    isLoadingTexts,
    isLoadingAuthFlow,
    isLoadingIcons,
  ]);

  if (isLoading) {
    return (
      <StyledThemeProvider theme={baseTheme}>
        <PageSpinnerWrapper>
          <Spinner />
        </PageSpinnerWrapper>
      </StyledThemeProvider>
    );
  }

  return (
    <StyledThemeProvider theme={customTheme}>
      <GlobalStyle />
      <FontContainer>{children}</FontContainer>
    </StyledThemeProvider>
  );
});
