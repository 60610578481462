import {
  Coverage,
  PetData,
  PetsPresetData,
  SelectedPetData,
  Sublimits,
} from '@src/store/pets/pets-store.types';
import {
  GetPrices,
  InsuranceProductType,
  InsuranceSubProduct,
  Risk,
  SelectedDate,
  SelectedDuration,
} from '@src/types';

export type InsuranceSum = number;

export type InsuranceScheme = Record<string, string[]>;

export type SelectedIProduct = undefined | InsuranceProductType;

export type InsuranceSubproducts = {
  subObjects: InsuranceSubProduct[];
  subObjectsDependencyScheme?: InsuranceScheme;
  totalMinLimit?: string;
  totalMaxLimit?: string;
};

export interface PetsStoreState {
  selectedIProduct?: InsuranceProductType;
  risks?: Risk[];
  insuranceSum: InsuranceSum;
  getPrices: GetPrices;
  getPricesFull: GetPrices;
  selectedDuration: null | SelectedDuration;
  selectedDate: null | SelectedDate;
  promoCode?: string;
  promoCodeApplyed?: string;
  risksPage: 'primary' | 'secondary';
  pets: PetData[];
  selectedPetData?: SelectedPetData;
  sublimits?: Sublimits;
  currentSubLimits?: Sublimits;
  selectedPetKindName?: string;
  currentSum: number;
  currentSumMinLimit: string;
  currentSumMaxLimit: string;
  currentSumErr: boolean;
  selectedId?: string;
  presetData?: PetsPresetData;
}

export const initPetsStoreState: PetsStoreState = {
  selectedIProduct: undefined,
  risks: undefined,
  insuranceSum: 200000,
  getPrices: undefined,
  getPricesFull: undefined,
  selectedDuration: null,
  selectedDate: null,
  promoCode: undefined,
  promoCodeApplyed: undefined,
  risksPage: 'primary',
  pets: [],
  selectedPetData: undefined,
  sublimits: undefined,
  currentSubLimits: undefined,
  selectedPetKindName: undefined,
  currentSum: 250_000,
  currentSumMinLimit: '0',
  currentSumMaxLimit: '0',
  currentSumErr: false,
  selectedId: undefined,
};
